import { Cacheable, MILLIS_DAY } from "@/vault/Cacheable";
import { Indexable } from "../GPTypes";
import { ParseObject } from "../ParseTypes";

export interface GPPersmission {
	canRead: boolean;
	canWrite: boolean;
	canManageMembers: boolean;
	canManageBilling: boolean;
	owner: boolean;
}

export default class GPTeamRole implements Indexable, Cacheable {
	id: string;
	title: string;
	teamID: string | null;
	minIOSVersion: string;
	permissions: GPPersmission;
	cacheDate: Date;
	timeToLive: number;

	constructor(
		id: string,
		manageMembers: boolean,
		read: boolean,
		title: string,
		billing: boolean,
		team: string | null,
		minIOSVersion: string,
		write: boolean,
		owner: boolean,
		cacheDate = new Date(),
		ttl = MILLIS_DAY
	) {
		this.id = id;
		this.title = title;
		this.teamID = team;
		this.minIOSVersion = minIOSVersion;
		this.permissions = {
			canRead: read,
			canWrite: write,
			canManageBilling: billing,
			canManageMembers: manageMembers,
			owner: owner
		};
		this.cacheDate = cacheDate;
		this.timeToLive = ttl;
	}

	public equals(role: GPTeamRole): boolean {
		return (
			this.id === role.id /* &&
			this.title === role.title &&
			this.teamID === role.teamID &&
			this.minIOSVersion === role.minIOSVersion &&
			this.permissions.canRead === role.permissions.canRead &&
			this.permissions.canWrite === role.permissions.canWrite &&
			this.permissions.canManageMembers === role.permissions.canManageMembers &&
			this.permissions.canManageBilling === role.permissions.canManageBilling */
		);
	}

	public static clone(role: GPTeamRole): GPTeamRole {
		return new GPTeamRole(
			role.id,
			role.permissions.canManageMembers,
			role.permissions.canRead,
			role.title,
			role.permissions.canManageBilling,
			role.teamID,
			role.minIOSVersion,
			role.permissions.canWrite,
			role.permissions.owner,
			role.cacheDate,
			role.timeToLive
		);
	}

	public static newFromParseObj(obj: ParseObject): GPTeamRole {
		const id = obj.id;
		const mgmMemb = obj.get("manageMembers");
		const read = obj.get("read");
		const title = obj.get("title");
		const billing = obj.get("billing");
		const minisv = obj.get("minIOSVersion");
		const write = obj.get("write");
		const owner = obj.get("owner");

		const team = obj.get("team");
		let teamId = null;
		if (team != null) {
			teamId = team.id;
		}
		return new GPTeamRole(
			id,
			mgmMemb,
			read,
			title,
			billing,
			teamId,
			minisv,
			write,
			owner
		);
	}
}
