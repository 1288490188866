import { parseService } from "@/services/parse.service";
import router from "@/router/index";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import GPUser from "@/data/GPUser";
import GPTeamMember from "@/data/teams/GPTeamMember";
import { useToast } from "vue-toastification";

interface Login {
	username: string;
	password: string;
}

export enum MessageType {
	INFO = "info",
	SUCCESS = "sucess",
	ERR = "error",
	NONE = "",
}

interface StatusMessage {
	message: string;
	type: MessageType;
}

export interface AccountStatus {
	loggedIn: boolean;
	accountSynced: boolean;
	updated?: boolean;
	msg?: StatusMessage;
	loggingIn?: boolean;
	registering?: boolean;
	registered?: boolean;
	pwresetting?: boolean;
	pwresetFailed?: boolean;
	editing?: boolean;
}

export class AccountState {
	user?: GPUser;
	memberProfiles?: GPTeamMember[];
	status: AccountStatus;

	constructor() {
		const pfuser = parseService.getCurrentUser();
		if (pfuser) {
			this.user = GPUser.fromParseUser();
			if (!this.memberProfiles) {
				parseService.getTeamMemberInfo().then((memberInfo) => {
					this.memberProfiles = memberInfo;
				});
			}
		}
		this.status = {
			loggedIn: !!this.user,
			msg: { message: "", type: MessageType.INFO },
			updated: false,
			accountSynced: true,
		};
	}
}

const getters = <GetterTree<AccountState, any>>{
	isLoggedIn(state): boolean {
		return state.status.loggedIn;
	},
	getFirstName(state): string | undefined {
		return state.user?.firstname;
	},
	getLastName(state): string | undefined {
		return state.user?.lastname;
	},
	getUname(state): string | undefined {
		return state.user?.username;
	},
	getMessage(state): string | undefined {
		return state.status.msg?.message;
	},
	// getTeams(state): GPTeam[] | undefined {
	// 	if (state.memberProfiles) {
	// 		return state.memberProfiles.map((profile) => {
	// 			return profile.team;
	// 		});
	// 	}
	// },
	getTeamMembers(state): GPTeamMember[] | undefined {
		return state.memberProfiles;
	},
};

const mutations = <MutationTree<AccountState>>{
	loginRequest(state) {
		state.status.loggedIn = true;
	},
	loginSuccess(state: any) {
		state.status.loggingIn = false;
		state.status.loggedIn = true;
	},
	loginFailure(state: any, error: Error) {
		state.status.loggingIn = false;
		state.status.loggedIn = false;
		// state.status.msg = {
		// 	message: error.message,
		// 	type: MessageType.ERR,
		// };
		state.user = undefined;
	},
	logoutMut(state: any) {
		state.status = {
			msg: { message: "Logout Successful", type: MessageType.NONE },
			loggedIn: false,
			updated: true,
		};
		state.user = undefined;
	},
	logoutMutTemp(state: any) {
		state.status = {
			// msg: { message: "Logout Successful", type: MessageType.NONE },
			loggedIn: false,
			updated: true,
		};
		state.user = undefined;
	},
	registerRequest(state: any) {
		state.status.registering = true;
	},
	registerSuccess(state: any) {
		state.status.registered = true;
		state.status.registering = false;
	},
	registerFailure(state: any, error: Error) {
		toast.error(error.message);
		// state.status.msg = { message: error.message, type: MessageType.ERR };
		state.status.registering = false;
	},
	clearMessages(state: any) {
		state.status.updated = false;
		toast.clear();
		// state.status.msg = { message: "", type: MessageType.NONE };
	},
	passwordResetRequest(state: any) {
		state.status.pwresetting = true;
	},
	passwordResetRequested(state: any) {
		state.status.pwresetting = false;
		toast.success("Password Reset Requested. Check your email.");
		// state.status.msg = {
		// 	message: "Password Reset Requested. Check your email.",
		// 	type: MessageType.INFO,
		// };
	},
	passwordResetFailed(state: any, error: any) {
		state.status.pwresetting = false;
		state.status.pwresetFailed = true;
		// state.status.msg = {
		// 	message: error.message,
		// 	type: MessageType.ERR,
		// };
		toast.error(error.message);
	},
	passwdChangeSucess(state: any) {
		// state.status.msg = {
		// 	message: "Password Changed",
		// 	type: MessageType.SUCCESS,
		// };
		toast.success("Password Changed");
		state.status.updated = true;
	},
	updateUser(state: any) {
		const pfuser = parseService.getCurrentUser();
		if (pfuser) {
			state.user = state.user = GPUser.fromParseUser();
			state.status.updated = true;
			// toast.success("User Successful Updated");
			// state.status.msg = {
			// 	message: "User Successfuly Updated",
			// 	type: MessageType.SUCCESS,
			// };
		} else {
			state.user = undefined;
		}
	},
	editUser(state: any) {
		state.status.editing = true;
	},
	endEditUser(state: any) {
		state.status.editing = false;
	},
	updateAvatarSucess(state: any) {
		state.status.updated = true;
		const message = "Avatar Successfuly Updated";
		toast.success(message);
		// state.status.msg = {
		// 	message: state.status.msg?.message
		// 		? state.status.msg?.message + " " + message
		// 		: message,
		// 	type: MessageType.SUCCESS,
		// };
	},
	updateAvatarFailed(state: any, error: Error) {
		state.status.updated = true;
		toast.error(error.message);
		// state.status.msg = {
		// 	message: error.message,
		// 	type: MessageType.ERR,
		// };
	},
};

const toast = useToast();

const actions = <ActionTree<AccountState, any>>{
	async login({ commit }, loginInfo: { username: string; password: string }) {
		commit("loginRequest");
		try {
			await parseService.login(loginInfo.username, loginInfo.password);
			commit("loginSuccess");
			commit("updateUser");
			toast.success("Login Successful");
			return true;
		} catch (error: any) {
			toast.error(error.message);
			commit("loginFailure", error);
			commit("updateUser");
			return false;
		}
		// parseService.login(loginInfo.username, loginInfo.password).then(
		// 	() => {
		// 		commit("loginSuccess");
		// 		commit("updateUser");
		// 		return true;
		// 	},
		// 	(error) => {
		// 		commit("loginFailure", error);
		// 		commit("updateUser");
		// 		return false;
		// 	}
		// );
	},

	async logout({ commit }) {
		await parseService.logout();
		commit("logoutMut");
		commit("updateUser");
	},

	async logoutTemp({ commit }) {
		await parseService.logout();
		commit("logoutMutTemp");
		commit("updateUser");
	},

	register({ commit, dispatch }, user: GPUser) {
		commit("registerRequest");
		parseService.registerUser(user).then(
			() => {				
				commit("registerSuccess");
				commit("updateUser");				
				router.push("/login");


				setTimeout(() => {
					dispatch("alert/success", "Registration successful", {
						root: true,
					});
				});
			},
			(error) => {
				commit("registerFailure", error);
				commit("updateUser");
				dispatch("alert/error", error, {
					root: true,
				});
			}
		);
	},

	updateAll({ commit }, user: any) {
		const userGP = user.user as GPUser;
		// console.log("STORE::updateAll", userGP);
		const userProps = Object.entries(userGP);
		console;
		for (const [property, value] of userProps) {
			if (value !== parseService.getUserProperty(property)) {
				parseService.updateUser(property, value);
			}
		}
		parseService.saveUser();
		commit("updateUser");
	},

	updateAvatar({ commit }, file: File): any {
		parseService.setAvatar(file.name, file).then(
			(parseFile) => {
				parseService.updateUser("avatarImage", parseFile);
				parseService.saveUser();
				commit("updateAvatarSucess");
				return true;
			},
			(error) => {
				commit("updateAvatarFailed", error);
				return false;
			}
		);
	},

	changePassword({ commit }, newPassword: string) {
		parseService.setPassword(newPassword);
		parseService.saveUser();
		commit("passwdChangeSucess");
	},

	resetPassword({ commit }, email: string) {
		commit("passwordResetRequest");
		parseService.requestPasswordReset(email).then(
			(response) => {
				if (response.success) {
					commit("passwordResetRequested");
				} else {
					commit("passwordResetFailed", response);
				}
			},
			(error) => {
				commit("passwordResetFailed", error);
			}
		);
	},
};

const AccountModule = {
	namespaced: true,
	state: new AccountState(),
	getters: getters,
	mutations: mutations,
	actions: actions,
};

export default AccountModule;
