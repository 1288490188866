
import { defineComponent } from "vue";

const __default__ = defineComponent({
  props: {
    img: {
      type: String,
      default: "https://designvault.io/wp-content/uploads/2020/06/05-1-600x1298.png",
    },
    reduceSizeForSmallScreen: {
      type: Boolean,
      default: false,
    },
    display: {
      type: String,
      default: "block",
    },
  },
  computed: {
		smallScreenImageWidth(): string {
			if (this.reduceSizeForSmallScreen) {
				return "77px";
			} else {
				return "272px";
			}
		},
		smallScreenImageHeight(): string {
			if (this.reduceSizeForSmallScreen) {
				return "120px";
			} else {
				return "425px";
			}
		},

		smallScreenParentHeight(): string {
			if (this.reduceSizeForSmallScreen) {
				return "120px";
			} else {
				return "272px";
			}
		},

		smallScreenScreenShotWidth(): string {
			if (this.reduceSizeForSmallScreen) {
				return "50px";
			} else {
				return "112px";
			}
		},
		
		smallScreenScreenShotTop(): string {
			if (this.reduceSizeForSmallScreen) {
				return "30px";
			} else {
				return "68px";
			}
		},

		smallScreenScreenShotLeft(): string {
			if (this.reduceSizeForSmallScreen) {
				return "11px";
			} else {
				return "76px";
			}
		},

	},
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "b8faa4bc": (_ctx.smallScreenImageWidth),
  "99f7e66a": (_ctx.smallScreenImageHeight),
  "f47dd5b0": (_ctx.smallScreenParentHeight),
  "3604fc6e": (_ctx.smallScreenScreenShotWidth),
  "9a078bd0": (_ctx.smallScreenScreenShotTop),
  "a6f180b8": (_ctx.smallScreenScreenShotLeft)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__