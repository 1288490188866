
import { defineComponent } from "vue";


const __default__ = defineComponent({
  props: {
    img: {
      type: String,
      default: "https://designvault.io/wp-content/uploads/2020/06/05-1-600x1298.png",
    }, 
		smallScreenScale: {
			type: String,
			default: "100%"
		},
		display: {
			type: String,
			default: "block"
		}	
  }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "828dfd8a": (_ctx.display),
  "4c387744": (_ctx.smallScreenScale)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__