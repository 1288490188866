import { coreData } from "@/services/CoreData";
import { Cacheable, MILLIS_DAY } from "@/vault/Cacheable";
import { Indexable } from "../GPTypes";
import GPUser from "../GPUser";
import { ParseObject } from "../ParseTypes";
import GPTeam from "./GPTeam";
import GPTeamRole, { GPPersmission } from "./GPTeamRole";

export default class GPTeamMember implements Indexable, Cacheable {
	id: string;
	userID: string;
	teamID: string;
	roleID: string;
	// team: GPTeam;
	// member?: GPUser;
	status: string;
	// role: GPTeamRole;
	// po?: ParseObject;
	cacheDate: Date;
	timeToLive: number;

	constructor(
		id: string,
		userID: string,
		teamID: string,
		roleID: string,
		status: string,
		cacheDate = new Date(),
		ttl = MILLIS_DAY
	) {
		this.id = id;
		this.userID = userID;
		this.teamID = teamID;
		this.roleID = roleID;
		this.status = status;
		this.cacheDate = cacheDate;
		this.timeToLive = ttl;
	}

	public static clone(teamMember: GPTeamMember): GPTeamMember {
		return new GPTeamMember(
			teamMember.id,
			teamMember.userID,
			teamMember.teamID,
			teamMember.roleID,
			teamMember.status,
			teamMember.cacheDate,
			teamMember.timeToLive
		);
	}

	public async getMemberUser(): Promise<GPUser | undefined> {
		return coreData.getUser(this.userID);
	}

	public async getTeam(): Promise<GPTeam | undefined> {
		return coreData.getTeam(this.teamID);
	}

	public async getRole(): Promise<GPTeamRole | undefined> {
		return coreData.getRole(this.roleID);
	}

	public async getPermissions(): Promise<GPPersmission | undefined> {
		const role = await this.getRole();
		return role?.permissions;
	}

	public get isActive(): boolean {
		return this.status == "active";
	}

	public static async newFromParseObj(obj: ParseObject): Promise<GPTeamMember | null> {
		const id = obj.id;
		const status = obj.get("status");
		const memberID = await obj.get("member").id;
		const roleID = await obj.get("role").id;
		const team = await obj.get("team");
		if (team != null) {
			const teamID = await team.id;

			return new GPTeamMember(id, memberID, teamID, roleID, status);
		} else {
			return null;
		}
	}
}
