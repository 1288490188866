/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import tooltipDirective from "./Tooltip";

// register all directives
// const directives = (app) => {
// 	tooltipDirective(app);
// };

function registerDirectives(app: any) {
	tooltipDirective(app);
}

export default registerDirectives;
