
import Header from "./components/navigation/GlobalHeader.vue";
import Footer from "./components/navigation/GlobalFooter2.vue";
import { defineComponent, ref, watch, computed } from "vue";
import { teamVault } from "./vault/Teams";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "App",
  components: {
    Header,
    Footer,
  },

  setup(props) {
    teamVault.fetchTeams();
    const route = useRoute();
    const userData = ref();

    var pathname = ref(window.location.pathname);

    //Watch for route changes so that we can update the pathname
    //When the pathname updates the computed function showFooter
    //updates which updates the v-if for showing the footer
    watch(
      () => route.path,
      async (newPath) => {
        pathname.value = window.location.pathname;
        console.log("Route Changed " + pathname.value);
      }
    );

    const showFooter = computed(() => {
      var path = pathname.value;
      //console.log("path " + path);
      var comps = path.split("/");
      if (comps.length > 1) {
        var firstPart = comps[1];
        return firstPart != "share" && firstPart != "map";
      }
      return true;
    });

    const isSharePage = computed(() => {
      var path = pathname.value;
      //console.log("path " + path);
      var comps = path.split("/");
      if (comps.length > 1) {
        var firstPart = comps[1];
        return firstPart == "share";
      }
      return false;
    });

    return { showFooter, isSharePage };
  },
});
