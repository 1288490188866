import {
	ApolloClient,
	createHttpLink,
	InMemoryCache,
} from "@apollo/client/core";

// HTTP connection to the API
const httpLink = createHttpLink({
	// You should use an absolute URL here
	uri: "https://parseapi.back4app.com/graphql",
	headers: {
		"X-Parse-Client-Key": "i8IHuseGWexs5UANzEZJJUS9krLBQs9eZia8QpFQ",
		"X-Parse-Application-Id": "TXBGm2OBL4UsoZrztji3kQA02R6Jj4cjntEhuCY5",
	},
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
	link: httpLink,
	cache,
});

export { apolloClient };
