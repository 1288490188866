import countries from "i18n-iso-countries";

// eslint-disable-next-line @typescript-eslint/no-var-requires
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

function zip<T, V>(a: T[], b: V[]): Array<[T, V]> {
	return a.map((k, i) => {
		return [k, b[i]];
	});
}

function stripHTML(html: string): string {
	const doc = new DOMParser().parseFromString(html, "text/html");
	return doc.body.textContent || "";
}

const isDebug = true; // toggle this to turn on / off for global control

// eslint-disable-next-line @typescript-eslint/no-empty-function
const debug = isDebug ? console.log.bind(window.console) : function () {};

// function that takes in a currency code and returns the currency symbol
function getCurrencySymbol(currencyCode: string): string {
	switch (currencyCode) {
		case "USD":
		case "CAD":
		case "AUD":
		case "NZD":
		case "SGD":
			return "$";
		case "EUR":
			return "€";
		case "GBP":
			return "£";
		case "JPY":
			return "¥";
		case "BRL":
			return "R$";
		case "CNY":
			return "¥";
		case "INR":
			return "₹";
		case "MXN":
			return "$";
		case "RUB":
			return "₽";
		case "SEK":
			return "kr";
		case "CHF":
			return "Fr";
		case "TRY":
			return "₺";
		case "NOK":
			return "kr";
		case "ZAR":
			return "R";
		case "KRW":
			return "₩";
		case "MYR":
			return "RM";
		case "IDR":
			return "Rp";
		case "PHP":
			return "₱";
		case "THB":
			return "฿";
		case "VND":
			return "₫";
		case "PLN":
			return "zł";
		case "HUF":
			return "Ft";
		case "CZK":
			return "Kč";
		case "ILS":
			return "₪";
		case "BGN":
			return "лв";
		case "RON":
			return "lei";
		case "DKK":
			return "kr";
		case "HRK":
			return "kn";
		case "LTL":
			return "Lt";
		case "ISK":
			return "kr";
		case "KZT":
			return "₸";
		default:
			return "";
	}
}

function getCountryname(countryCode: string): string {
	// console.log(`Getting name for countryCode: '${countryCode}'`);
	const name = countries.getName(countryCode, "en", { select: "official" });
	// console.log("Got name:", name);
	return name;
}

// function that takes a date and locale string and returns a human readable date and time
// it also checks to make sure the date is not null or undefined
// it also checks that the locale is formatted correctly
function formattedDateTime(date?: Date, locale?: string): string {
	if (date === null || date === undefined) {
		return "";
	}
	// if (locale === null || locale === undefined) {
	// 	return date.toLocaleString();
	// }

	// const formattedLocale = locale.replace("_", "-")
	// const options = {
	// 	year: 'numeric',
	// 	month: 'long',
	// 	day: 'numeric',
	// 	hour: 'numeric',
	// 	minute: 'numeric',
	// 	second: 'numeric',
	// 	hour12: false,
	// };
	return date.toLocaleString("en", {
		timeZoneName: "short",
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
	});
}

// function formatDate(date: Date) {
// 	const monthNames = [
// 		"January",
// 		"February",
// 		"March",
// 		"April",
// 		"May",
// 		"June",
// 		"July",
// 		"August",
// 		"September",
// 		"October",
// 		"November",
// 		"December",
// 	];

// 	const day = date.getDate();
// 	const monthIndex = date.getMonth();
// 	const year = date.getFullYear();
// 	const hours = date.getHours();
// 	const minutes = date.getMinutes();
// 	const seconds = date.getSeconds();

// 	const timezone = date.toLocaleTimeString(
// 		user.value?.locale.replace("_", "-").substring(0, 5) ?? "",
// 		{
// 			timeZoneName: "short",
// 		}
// 	);

// 	return `${monthNames[monthIndex]} ${day}, ${year} (${timezone})`;
// }

export {
	zip,
	debug,
	stripHTML,
	getCurrencySymbol,
	formattedDateTime,
	getCountryname,
};
