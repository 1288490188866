import { WPPage, WPPost } from "@/data/Wordpress";
import { DBSchema, IDBPDatabase, openDB } from "idb";

interface WordpressDB extends DBSchema {
	pages: {
		key: string;
		value: WPPage;
		indexes: { id: number; slug: string };
	};
	posts: {
		key: string;
		value: WPPost;
		indexes: { id: number; slug: string };
	};
}

const DB_NAME = "gp_wordpress";

export class WPVault {
	db: Promise<IDBPDatabase<WordpressDB>>;

	constructor() {
		this.db = openDB(DB_NAME, 1, {
			upgrade(db) {
				// Setup pages objectStore
				const uos = db.createObjectStore("pages", {
					keyPath: "id",
				});
				uos.createIndex("id", "id", { unique: true });
				uos.createIndex("slug", "slug", { unique: true });

				// Setup sections objectStore
				const tos = db.createObjectStore("posts", {
					keyPath: "id",
				});
				tos.createIndex("id", "id", { unique: true });
				tos.createIndex("slug", "slug", { unique: true });

				console.log(`Database setup complete: ${DB_NAME}`);
			},
		});
	}

	public async fetchPage(slug: string): Promise<WPPage | undefined> {
		const dbOp = await this.db;
		const page = await dbOp.getFromIndex("pages", "slug", slug);
		return page;
	}

	public async cachePage(page: WPPage): Promise<void> {
		const dbOp = await this.db;
		await dbOp.put("pages", page);
	}

	public async fetchPost(slug: string): Promise<WPPost | undefined> {
		const dbOp = await this.db;
		const post = await dbOp.getFromIndex("posts", "slug", slug);
		return post;
	}

	public async cachePost(post: WPPost): Promise<void> {
		const dbOp = await this.db;
		await dbOp.put("posts", post);
	}

	public async getAllPages(): Promise<Array<WPPage>> {
		const dbOp = await this.db;
		const pages = await dbOp.getAll("pages");
		return pages;
	}

	public async getAllPosts(): Promise<Array<WPPost>> {
		const dbOp = await this.db;
		const posts = await dbOp.getAll("posts");
		return posts;
	}
}
