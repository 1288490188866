import { Convert, WPPage, WPPost } from "@/data/Wordpress";

const WP_API_URL = "https://www.support.glacierpeak.app/wp-json/wp/v2";

function convertPage(json: any): WPPage {
	// console.log("convertPage:", json);
	return Convert.toWPPage(JSON.stringify(json));
}

function convertPost(json: any): WPPost {
	// console.log("convertPost", json);
	return Convert.toWPPost(JSON.stringify(json));
}

async function getPostFromSlug(slug: string): Promise<WPPost | undefined> {
	const response = await fetch(WP_API_URL + `/posts?slug=${slug}`);
	const json = await response.json();
	const post = json[0];
	// console.log("postJson", post);
	try {
		const postObj = Convert.toWPPost(JSON.stringify(post));
		return postObj;
	} catch (e) {
		return undefined;
	}
}

async function getPageFromSlug(slug: string): Promise<WPPage | undefined> {
	const response = await fetch(WP_API_URL + `/pages?slug=${slug}`);
	const json = await response.json();
	const page = json[0];
	// console.log("pageJson", page);
	try {
		const pageObj = Convert.toWPPage(JSON.stringify(page));
		return pageObj;
	} catch (e) {
		return undefined;
	}
}

async function getFromSlug(slug: string): Promise<WPPost | WPPage | undefined> {
	const post = await getPostFromSlug(slug);
	if (post) {
		return post;
	}
	const page = await getPageFromSlug(slug);
	if (page) {
		return page;
	}
}

async function getRecentPosts(count = 20): Promise<WPPost[]> {
	const response = await fetch(WP_API_URL + `/posts?number=${count}`);
	const json = await response.json();
	const posts = json.map(convertPost);
	return posts;
}

async function getRecentPages(count = 20): Promise<WPPage[]> {
	const response = await fetch(WP_API_URL + `/pages?number=${count}`);
	const json = await response.json();
	const pages = json.map(convertPage);
	return pages;
}

async function getRecent(): Promise<Array<WPPage | WPPost>> {
	// const posts = await getRecentPosts();
	const pages = await getRecentPages();
	// return [...posts, ...pages];
	return [...pages];
}

export const wpservice = {
	getPostFromSlug,
	getRecentPosts,
	getFromSlug,
	getPageFromSlug,
	getRecentPages,
	getRecent,
};
