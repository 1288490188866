import GPTeamMember from "@/data/teams/GPTeamMember";
import { parseService } from "@/services/parse.service";
import { ComputedRef, ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import { Ref } from "vue";
import { coreData } from "@/services/CoreData";
import GPTeam from "@/data/teams/GPTeam";

class MemberVault {
	memberProfiles: Ref<GPTeamMember[]>;
	teams: Ref<GPTeam[]>;
	profiles: ComputedRef<GPTeamMember[]> = computed(
		() => this.memberProfiles.value
	);

	teamList: ComputedRef<GPTeam[]> = computed(() => this.teams.value);

	// teams: ComputedRef<GPTeam[]> = computed(() =>
	// 	this.memberProfiles.value.map((profile) => profile.team)
	// );

	// cache: IDBDatabase | undefined;

	constructor() {
		this.memberProfiles = ref<GPTeamMember[]>([]);
		this.teams = ref<GPTeam[]>([]);

		this.fetchRemote();
		// this.cache = db;

		// const request = window.indexedDB.open(DB_NAME, 1);

		// request.onsuccess = () => {
		// 	this.cache = request.result;
		// };

		// request.onerror = () => {
		// 	// 			console.log("Database failed to open");
		// };

		// request.onupgradeneeded = function (e) {
		// 	if (e.target) {
		// 		// Grab a reference to the opened database
		// 		const dba = request.result;

		// 		// Create an objectStore to store our notes in (basically like a single table)
		// 		// including a auto-incrementing key
		// 		const objectStore = dba.createObjectStore(DB_NAME, {
		// 			keyPath: "id",
		// 		});

		// 		// Define what data items the objectStore will contain
		// 		objectStore.createIndex("id", "id", { unique: true });
		// 		objectStore.createIndex("team", "team", { unique: false });
		// 		objectStore.createIndex("member", "member", { unique: false });
		// 		objectStore.createIndex("status", "status", { unique: false });
		// 		objectStore.createIndex("role", "role", { unique: false });
		// 		objectStore.createIndex("po", "po", { unique: false });
		//
		// 		console.log("Database setup complete");
		// 	}
	}

	async getMemberProfiles(): Promise<GPTeamMember[]> {
		this.fetchRemote();
		return this.memberProfiles.value;
	}

	async getTeams(): Promise<GPTeam[]> {
		this.fetchRemote();
		return this.teams.value;
	}

	// get teamList(): GPTeam[] {
	// 	return this.memberProfiles.value.map((profile) => profile.team);
	// }

	async fetchTeams(): Promise<void> {
		// this.fetchRemote();
		try {
			// const cache = await this.cache;
			const teamMembers = await coreData.getAllMembers();
			if (teamMembers.length !== 0) {
				for (const member of teamMembers) {
					this.addTeamMember(member);
				}
			} else {
				await this.fetchRemote();
			}
		} catch (err) {
			await this.fetchRemote();
		}
	}

	private addTeamMember(member: GPTeamMember) {
		if (
			this.memberProfiles.value
				.map((profile) => profile.id)
				.indexOf(member.id) === -1
		) {
			this.memberProfiles.value.push(member);
		}
	}

	private addTeam(team: GPTeam) {
		if (this.teams.value.map((profile) => profile.id).indexOf(team.id) === -1) {
			this.teams.value.push(team);
		}
	}

	async fetchRemote(): Promise<void> {
		// 		console.log("Fetching temMembers from remote");
		const memberObjs = await parseService.getRawTeamMemberInfo();
		if (memberObjs) {
			for (const memberObj of memberObjs) {
				const member = await coreData.getMember(memberObj.id);
				if (member) {
					this.addTeamMember(member);
					const team = await coreData.getTeam(member.teamID);
					if (team) {
						this.addTeam(team);
					}
				}
				// GPTeamMember.newFromParseObj(memberObj).then((member) => {
				// 	this.addTeamMember(member);
				// 	this.cacheTeamMember(member);
				// });
			}
		}
	}

	async clearCache(): Promise<void> {
		this.memberProfiles.value = [];
		this.teams.value = [];
		try {
			await coreData.clearCache();
		} catch (err) {
			console.log(err);
		}
	}
}
export const teamVault = new MemberVault();
