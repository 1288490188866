import { parseService } from "@/services/parse.service";
import { Cacheable, MILLIS_DAY } from "@/vault/Cacheable";
import { Indexable } from "./GPTypes";
import { ParseFile, ParseUser } from "./ParseTypes";
import { SimpleListItem } from "./UITypes";

export function activityList(activities?: string): string[] {
	if (activities) {
		return activities.split(",");
	}
	return [];
}
export default class GPUser implements Indexable, Cacheable, SimpleListItem {
	id: string = "usr" + Math.floor(Math.random() * 2000) + "rn";
	username = "";
	fname = "";
	lname = "";
	email = "";
	newsletter = false;
	avatarImg: ParseFile | null = null;
	regForPush = false;
	emailVerified = false;
	activities = "";
	updatedAt: Date = new Date();
	createdAt: Date = new Date();
	headerImage: ParseFile | null = null;
	country = "";
	locale = "";
	cacheDate: Date;
	timeToLive: number;
	isAdmin = false;

	constructor(user?: GPUser, ttl = MILLIS_DAY) {
		if (user) {
			this.id = user.id;
			this.username = user.username;
			this.fname = user.fname;
			this.lname = user.lname;
			this.email = user.email;
			this.newsletter = user.newsletter;
			this.avatarImg = user.avatarImg;
			this.regForPush = user.regForPush;
			this.emailVerified = user.emailVerified;
			this.activities = user.activities;
			this.updatedAt = user.updatedAt;
			this.createdAt = user.createdAt;
			this.headerImage = user.headerImage;
			this.country = user.country;
			this.locale = user.locale;
			this.isAdmin = user.isAdmin;
		}
		this.cacheDate = new Date();
		this.timeToLive = ttl;
	}

	static fromParseUser(userObj?: ParseUser): GPUser | undefined {
		console.log("making user from parseUser::", userObj);
		let user = userObj;
		if (!userObj) {
			user = parseService.getCurrentUser();
		}
		if (user) {
			// console.log("Found user", user);
			const usr = new GPUser();
			// Object.getOwnPropertyNames(usr).forEach(function (val, _idx, _array) {
			// 	Object.assign(usr, { [val]: user.get(val) });
			// });

			usr.id = user.id;
			usr.username = user.get("username");
			usr.fname = user.get("firstname");
			usr.lname = user.get("lastname");
			usr.email = user.get("email");
			usr.newsletter = user.get("newsletter");
			usr.avatarImg = user.get("avatarImage");
			usr.regForPush = user.get("registeredForPush") ?? false;
			usr.emailVerified = user.get("emailVerified");
			usr.activities = user.get("activities");
			usr.updatedAt = user.get("updatedAt");
			usr.createdAt = user.get("createdAt");
			usr.headerImage = user.get("headerImage");
			usr.country = user.get("country");
			usr.locale = user.get("locale");
			usr.isAdmin = user.get("isAdmin");

			return usr;
		}
		console.log("Returning undefinded user!!");
		return undefined;
	}

	get name(): string {
		if (this.fname || this.lname) {
			return `${this.fname} ${this.lname}`;
		}
		return this.username;
	}

	get activityList(): string[] {
		if (this.activities) {
			return this.activities.split(",");
		}
		return [];
	}

	get title(): string {
		return this.name;
	}

	get subtitle(): string {
		return this.id;
	}

	get clickLink(): string {
		return `admin/user/${this.id}`;
	}

	get icon(): string {
		return this.avatarURL;
	}

	// set name(value: string) {
	// 	const name = value.split(" ");
	// 	this.fname = name[0];
	// 	this.lname = name[1];
	// }

	get firstname(): string {
		return this.fname;
	}

	get lastname(): string {
		return this.lname;
	}

	get avatarURL(): string {
		return GPUser.getAvatarURL(this);
	}

	get displayName(): string {
		return this.firstname || this.username || "User";
	}

	get uname(): string {
		return this.username || this.email;
	}

	// eslint-disable-next-line no-use-before-define
	static getAvatarURL(user?: GenericUser): string {
		if (user) {
			let avatarImgFile: Parse.File | null = null;
			try {
				const pUser = user as ParseUser;
				avatarImgFile = pUser.get("avatarImage");
			} catch {
				const gpUser = user as GPUser;
				avatarImgFile = gpUser.avatarImg;
			}
			if (avatarImgFile) {
				return avatarImgFile._url;
			}
		}
		return require("@/assets/images/img/default-user-img.webp");
	}
}

function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
	return o[propertyName]; // o[propertyName] is of type T[K]
}

type GenericUser = GPUser | ParseUser;
