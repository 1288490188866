export const MILLIS_DAY = 86400000;

export interface Cacheable {
	cacheDate: Date;
	timeToLive: number;
}

export function isCacheValid(cacheable: Cacheable): boolean {
	return (
		cacheable.cacheDate &&
		cacheable.cacheDate.getTime() + cacheable.timeToLive > Date.now()
	);
}

export function extendTTL(
	cacheable: Cacheable,
	timeToLive = MILLIS_DAY
): Cacheable {
	cacheable.timeToLive = timeToLive;
	return cacheable;
}
