import { createStore } from "vuex";
import AccountModule from "./modules/Account";

export const store = createStore({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		account: AccountModule,
	},
});
export default store;
