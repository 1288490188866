import { createApp } from "vue";
import App from "./App.vue";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "@/assets/css/style.css";
// @import "~vue-maplibre-gl/src/css/maplibre";
import router from "./router";
import store from "./store/store";
import directives from "@/directives";
import { createHead } from "@vueuse/head";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { apolloClient } from "./services/B4ApolloClient";
import Toast, { PluginOptions, POSITION, TYPE } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import Amplify from "aws-amplify";
import aws_exports from "./aws-exports";
import {
	applyPolyfills,
	defineCustomElements,
} from "@aws-amplify/ui-components/loader";
import VueMaplibreGl from 'vue-maplibre-gl';

Amplify.configure(aws_exports);
applyPolyfills().then(() => {
	defineCustomElements(window);
});

const app = createApp(App);

const toastOptions: PluginOptions = {
	toastDefaults: {
		// ToastOptions object for each type of toast
		[TYPE.ERROR]: {
			timeout: 5000,
		},
		[TYPE.SUCCESS]: {
			timeout: 3000,
		},
	},
	position: POSITION.TOP_CENTER,
	hideProgressBar: true,
	// You can set your default options here
};
app.use(Toast, toastOptions);

app.use(VueMaplibreGl)

app.provide(DefaultApolloClient, apolloClient);
directives(app);
const head = createHead();

app.use(head);

app.use(store).use(router);

app.mount("#app");
