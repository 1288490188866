const priceTiers = new Map<string, Map<number, number>>([
	[
		"USD",
		new Map([
			[17, 16.99],
			[41, 40.99],
			[14, 13.99],
			[20, 19.99],
			[30, 29.99],
			[49, 48.99],
			[38, 37.99],
			[74, 219.99],
			[68, 169.99],
			[77, 249.99],
			[3, 2.99],
			[42, 41.99],
			[32, 31.99],
			[58, 89.99],
			[47, 46.99],
			[28, 27.99],
			[59, 94.99],
			[39, 38.99],
			[34, 33.99],
			[6, 5.99],
			[46, 45.99],
			[50, 49.99],
			[67, 159.99],
			[81, 449.99],
			[84, 699.99],
			[12, 11.99],
			[23, 22.99],
			[25, 24.99],
			[5, 4.99],
			[7, 6.99],
			[78, 299.99],
			[45, 44.99],
			[43, 42.99],
			[63, 124.99],
			[11, 10.99],
			[37, 36.99],
			[16, 15.99],
			[2, 1.99],
			[51, 54.99],
			[64, 129.99],
			[31, 30.99],
			[26, 25.99],
			[61, 109.99],
			[66, 149.99],
			[73, 209.99],
			[8, 7.99],
			[79, 349.99],
			[65, 139.99],
			[40, 39.99],
			[27, 26.99],
			[24, 23.99],
			[71, 189.99],
			[83, 599.99],
			[82, 499.99],
			[13, 12.99],
			[72, 199.99],
			[70, 179.99],
			[19, 18.99],
			[76, 239.99],
			[21, 20.99],
			[9, 8.99],
			[60, 99.99],
			[44, 43.99],
			[87, 999.99],
			[35, 34.99],
			[86, 899.99],
			[1, 0.99],
			[80, 399.99],
			[10, 9.99],
			[75, 229.99],
			[55, 74.99],
			[15, 14.99],
			[29, 28.99],
			[52, 59.99],
			[48, 47.99],
			[56, 79.99],
			[33, 32.99],
			[54, 69.99],
			[85, 799.99],
			[22, 21.99],
			[18, 17.99],
			[4, 3.99],
			[53, 64.99],
			[62, 119.99],
			[36, 35.99],
			[57, 84.99],
			[69, 174.99],
		]),
	],
	[
		"EUR",
		new Map([
			[17, 18.99],
			[41, 44.99],
			[14, 14.99],
			[20, 21.99],
			[30, 32.99],
			[49, 53.99],
			[38, 41.99],
			[74, 249.99],
			[68, 189.99],
			[77, 279.99],
			[3, 3.49],
			[42, 46.99],
			[32, 34.99],
			[58, 99.99],
			[47, 51.99],
			[28, 30.99],
			[59, 104.99],
			[39, 42.99],
			[34, 37.99],
			[6, 6.99],
			[46, 50.99],
			[50, 54.99],
			[67, 179.99],
			[81, 499.99],
			[84, 799.99],
			[12, 12.99],
			[23, 24.99],
			[25, 27.99],
			[5, 5.49],
			[7, 7.99],
			[78, 329.99],
			[45, 49.99],
			[43, 47.99],
			[63, 139.99],
			[11, 11.99],
			[37, 40.99],
			[16, 17.99],
			[2, 2.29],
			[51, 59.99],
			[64, 149.99],
			[31, 33.99],
			[26, 28.99],
			[61, 119.99],
			[66, 169.99],
			[73, 239.99],
			[8, 8.99],
			[79, 399.99],
			[65, 159.99],
			[40, 43.99],
			[27, 29.99],
			[24, 26.99],
			[71, 219.99],
			[83, 699.99],
			[82, 599.99],
			[13, 13.99],
			[72, 229.99],
			[70, 209.99],
			[19, 20.99],
			[76, 269.99],
			[21, 22.99],
			[9, 9.99],
			[60, 109.99],
			[44, 48.99],
			[87, 1099.99],
			[35, 38.99],
			[86, 999.99],
			[1, 1.09],
			[80, 449.99],
			[10, 10.99],
			[75, 259.99],
			[55, 79.99],
			[15, 16.99],
			[29, 31.99],
			[52, 64.99],
			[48, 52.99],
			[56, 89.99],
			[33, 36.99],
			[54, 74.99],
			[85, 899.99],
			[22, 23.99],
			[18, 19.99],
			[4, 4.49],
			[53, 69.99],
			[62, 129.99],
			[36, 39.99],
			[57, 94.99],
			[69, 199.99],
		]),
	],
	[
		"GBP",
		new Map([
			[17, 16.99],
			[41, 39.99],
			[14, 13.99],
			[20, 19.99],
			[30, 28.99],
			[49, 47.99],
			[38, 36.99],
			[74, 219.99],
			[68, 169.99],
			[77, 249.99],
			[3, 2.99],
			[42, 40.99],
			[32, 30.99],
			[58, 84.99],
			[47, 45.99],
			[28, 26.99],
			[59, 89.99],
			[39, 37.99],
			[34, 32.99],
			[6, 5.99],
			[46, 44.99],
			[50, 48.99],
			[67, 159.99],
			[81, 449.99],
			[84, 699.99],
			[12, 11.99],
			[23, 21.99],
			[25, 23.99],
			[5, 4.99],
			[7, 6.99],
			[78, 299.99],
			[45, 43.99],
			[43, 41.99],
			[63, 124.99],
			[11, 10.99],
			[37, 35.99],
			[16, 15.99],
			[2, 1.99],
			[51, 49.99],
			[64, 129.99],
			[31, 29.99],
			[26, 24.99],
			[61, 109.99],
			[66, 149.99],
			[73, 209.99],
			[8, 7.99],
			[79, 349.99],
			[65, 139.99],
			[40, 38.99],
			[27, 25.99],
			[24, 22.99],
			[71, 189.99],
			[83, 599.99],
			[82, 499.99],
			[13, 12.99],
			[72, 199.99],
			[70, 179.99],
			[19, 18.99],
			[76, 239.99],
			[21, 20.49],
			[9, 8.99],
			[60, 99.99],
			[44, 42.99],
			[87, 999.99],
			[35, 33.99],
			[86, 899.99],
			[1, 0.99],
			[80, 399.99],
			[10, 9.99],
			[75, 229.99],
			[55, 69.99],
			[15, 14.99],
			[29, 27.99],
			[52, 54.99],
			[48, 46.99],
			[56, 74.99],
			[33, 31.99],
			[54, 64.99],
			[85, 799.99],
			[22, 20.99],
			[18, 17.99],
			[4, 3.99],
			[53, 59.99],
			[62, 119.99],
			[36, 34.99],
			[57, 79.99],
			[69, 174.99],
		]),
	],
	[
		"NZD",
		new Map([
			[17, 24.99],
			[41, 60.99],
			[14, 20.99],
			[20, 29.99],
			[30, 44.99],
			[49, 72.99],
			[38, 56.99],
			[74, 329.99],
			[68, 249.99],
			[77, 369.99],
			[3, 4.49],
			[42, 62.99],
			[32, 47.99],
			[58, 129.99],
			[47, 69.99],
			[28, 41.99],
			[59, 139.99],
			[39, 57.99],
			[34, 50.99],
			[6, 8.99],
			[46, 68.99],
			[50, 74.99],
			[67, 239.99],
			[81, 649.99],
			[84, 999.99],
			[12, 17.99],
			[23, 33.99],
			[25, 36.99],
			[5, 7.49],
			[7, 9.99],
			[78, 449.99],
			[45, 66.99],
			[43, 63.99],
			[63, 189.99],
			[11, 15.99],
			[37, 54.99],
			[16, 23.99],
			[2, 2.99],
			[51, 79.99],
			[64, 199.99],
			[31, 45.99],
			[26, 38.99],
			[61, 159.99],
			[66, 219.99],
			[73, 309.99],
			[8, 11.99],
			[79, 499.99],
			[65, 209.99],
			[40, 59.99],
			[27, 39.99],
			[24, 35.99],
			[71, 279.99],
			[83, 899.99],
			[82, 749.99],
			[13, 18.99],
			[72, 299.99],
			[70, 269.99],
			[19, 27.99],
			[76, 359.99],
			[21, 30.99],
			[9, 12.99],
			[60, 149.99],
			[44, 65.99],
			[87, 1499.99],
			[35, 51.99],
			[86, 1299.99],
			[1, 1.49],
			[80, 599.99],
			[10, 14.99],
			[75, 339.99],
			[55, 109.99],
			[15, 21.99],
			[29, 42.99],
			[52, 89.99],
			[48, 71.99],
			[56, 119.99],
			[33, 48.99],
			[54, 99.99],
			[85, 1199.99],
			[22, 32.99],
			[18, 26.99],
			[4, 5.99],
			[53, 94.99],
			[62, 179.99],
			[36, 53.99],
			[57, 124.99],
			[69, 259.99],
		]),
	],
	[
		"NOK",
		new Map([
			[17, 179.0],
			[41, 449.0],
			[14, 149.0],
			[20, 219.0],
			[30, 329.0],
			[49, 529.0],
			[38, 409.0],
			[74, 2390.0],
			[68, 1750.0],
			[77, 2690.0],
			[3, 33.0],
			[42, 459.0],
			[32, 349.0],
			[58, 949.0],
			[47, 509.0],
			[28, 299.0],
			[59, 999.0],
			[39, 419.0],
			[34, 369.0],
			[6, 65.0],
			[46, 499.0],
			[50, 549.0],
			[67, 1690.0],
			[81, 4790.0],
			[84, 7490.0],
			[12, 129.0],
			[23, 249.0],
			[25, 269.0],
			[5, 55.0],
			[7, 75.0],
			[78, 3290.0],
			[45, 489.0],
			[43, 469.0],
			[63, 1290.0],
			[11, 119.0],
			[37, 399.0],
			[16, 169.0],
			[2, 22.0],
			[51, 599.0],
			[64, 1390.0],
			[31, 339.0],
			[26, 279.0],
			[61, 1190.0],
			[66, 1590.0],
			[73, 2290.0],
			[8, 85.0],
			[79, 3790.0],
			[65, 1490.0],
			[40, 439.0],
			[27, 289.0],
			[24, 259.0],
			[71, 1990.0],
			[83, 6490.0],
			[82, 5490.0],
			[13, 139.0],
			[72, 2190.0],
			[70, 1890.0],
			[19, 199.0],
			[76, 2590.0],
			[21, 229.0],
			[9, 99.0],
			[60, 1090.0],
			[44, 479.0],
			[87, 10990.0],
			[35, 379.0],
			[86, 9490.0],
			[1, 11.0],
			[80, 4290.0],
			[10, 109.0],
			[75, 2490.0],
			[55, 799.0],
			[15, 159.0],
			[29, 309.0],
			[52, 649.0],
			[48, 519.0],
			[56, 849.0],
			[33, 359.0],
			[54, 749.0],
			[85, 8490.0],
			[22, 239.0],
			[18, 189.0],
			[4, 44.0],
			[53, 699.0],
			[62, 1250.0],
			[36, 389.0],
			[57, 899.0],
			[69, 1790.0],
		]),
	],
	[
		"CHF",
		new Map([
			[17, 17.0],
			[41, 40.0],
			[14, 14.0],
			[20, 20.0],
			[30, 29.0],
			[49, 47.5],
			[38, 37.0],
			[74, 220.0],
			[68, 170.0],
			[77, 250.0],
			[3, 3.0],
			[42, 41.0],
			[32, 31.0],
			[58, 90.0],
			[47, 46.0],
			[28, 28.0],
			[59, 95.0],
			[39, 38.0],
			[34, 33.0],
			[6, 6.0],
			[46, 45.0],
			[50, 48.0],
			[67, 160.0],
			[81, 450.0],
			[84, 700.0],
			[12, 12.0],
			[23, 23.0],
			[25, 25.0],
			[5, 5.0],
			[7, 7.0],
			[78, 300.0],
			[45, 44.0],
			[43, 42.0],
			[63, 125.0],
			[11, 11.0],
			[37, 36.0],
			[16, 16.0],
			[2, 2.0],
			[51, 55.0],
			[64, 130.0],
			[31, 30.0],
			[26, 26.0],
			[61, 110.0],
			[66, 150.0],
			[73, 210.0],
			[8, 8.0],
			[79, 350.0],
			[65, 140.0],
			[40, 39.0],
			[27, 27.0],
			[24, 24.0],
			[71, 190.0],
			[83, 600.0],
			[82, 500.0],
			[13, 13.0],
			[72, 200.0],
			[70, 180.0],
			[19, 19.0],
			[76, 240.0],
			[21, 21.0],
			[9, 9.0],
			[60, 100.0],
			[44, 43.0],
			[87, 1000.0],
			[35, 34.0],
			[86, 900.0],
			[1, 1.0],
			[80, 400.0],
			[10, 10.0],
			[75, 230.0],
			[55, 75.0],
			[15, 15.0],
			[29, 28.5],
			[52, 60.0],
			[48, 47.0],
			[56, 80.0],
			[33, 32.0],
			[54, 70.0],
			[85, 800.0],
			[22, 22.0],
			[18, 18.0],
			[4, 4.0],
			[53, 65.0],
			[62, 120.0],
			[36, 35.0],
			[57, 85.0],
			[69, 175.0],
		]),
	],
	[
		"JPY",
		new Map([
			[17, 2000.0],
			[41, 5000.0],
			[14, 1700.0],
			[20, 2400.0],
			[30, 3600.0],
			[49, 5900.0],
			[38, 4600.0],
			[74, 26800.0],
			[68, 19800.0],
			[77, 29800.0],
			[3, 360.0],
			[42, 5100.0],
			[32, 3800.0],
			[58, 10800.0],
			[47, 5700.0],
			[28, 3400.0],
			[59, 11400.0],
			[39, 4700.0],
			[34, 4100.0],
			[6, 720.0],
			[46, 5600.0],
			[50, 6000.0],
			[67, 18800.0],
			[81, 54800.0],
			[84, 84800.0],
			[12, 1400.0],
			[23, 2800.0],
			[25, 3000.0],
			[5, 600.0],
			[7, 840.0],
			[78, 34800.0],
			[45, 5400.0],
			[43, 5200.0],
			[63, 14800.0],
			[11, 1300.0],
			[37, 4500.0],
			[16, 1900.0],
			[2, 240.0],
			[51, 6800.0],
			[64, 15800.0],
			[31, 3700.0],
			[26, 3100.0],
			[61, 12800.0],
			[66, 17800.0],
			[73, 24800.0],
			[8, 960.0],
			[79, 42800.0],
			[65, 16800.0],
			[40, 4800.0],
			[27, 3200.0],
			[24, 2900.0],
			[71, 22800.0],
			[83, 74800.0],
			[82, 58800.0],
			[13, 1600.0],
			[72, 23800.0],
			[70, 21800.0],
			[19, 2300.0],
			[76, 28800.0],
			[21, 2500.0],
			[9, 1080.0],
			[60, 11800.0],
			[44, 5300.0],
			[87, 118800.0],
			[35, 4200.0],
			[86, 108800.0],
			[1, 120.0],
			[80, 48800.0],
			[10, 1200.0],
			[75, 27800.0],
			[55, 8800.0],
			[15, 1800.0],
			[29, 3500.0],
			[52, 7400.0],
			[48, 5800.0],
			[56, 9800.0],
			[33, 4000.0],
			[54, 8400.0],
			[85, 98800.0],
			[22, 2600.0],
			[18, 2200.0],
			[4, 480.0],
			[53, 7800.0],
			[62, 13800.0],
			[36, 4300.0],
			[57, 10400.0],
			[69, 20800.0],
		]),
	],
	[
		"RUB",
		new Map([
			[17, 1290.0],
			[41, 3090.0],
			[14, 1090.0],
			[20, 1490.0],
			[30, 2290.0],
			[49, 3690.0],
			[38, 2890.0],
			[74, 16990.0],
			[68, 12990.0],
			[77, 18990.0],
			[3, 229.0],
			[42, 3190.0],
			[32, 2390.0],
			[58, 6990.0],
			[47, 3590.0],
			[28, 2090.0],
			[59, 7290.0],
			[39, 2950.0],
			[34, 2590.0],
			[6, 459.0],
			[46, 3490.0],
			[50, 3790.0],
			[67, 11990.0],
			[81, 34990.0],
			[84, 54990.0],
			[12, 899.0],
			[23, 1750.0],
			[25, 1890.0],
			[5, 379.0],
			[7, 529.0],
			[78, 22990.0],
			[45, 3390.0],
			[43, 3250.0],
			[63, 9490.0],
			[11, 849.0],
			[37, 2790.0],
			[16, 1190.0],
			[2, 149.0],
			[51, 3990.0],
			[64, 9990.0],
			[31, 2350.0],
			[26, 1990.0],
			[61, 8490.0],
			[66, 11490.0],
			[73, 15990.0],
			[8, 599.0],
			[79, 26990.0],
			[65, 10990.0],
			[40, 2990.0],
			[27, 2050.0],
			[24, 1790.0],
			[71, 14490.0],
			[83, 44990.0],
			[82, 37990.0],
			[13, 999.0],
			[72, 14990.0],
			[70, 13990.0],
			[19, 1450.0],
			[76, 17990.0],
			[21, 1590.0],
			[9, 699.0],
			[60, 7490.0],
			[44, 3290.0],
			[87, 74990.0],
			[35, 2650.0],
			[86, 69990.0],
			[1, 75.0],
			[80, 29990.0],
			[10, 749.0],
			[75, 17490.0],
			[55, 5790.0],
			[15, 1150.0],
			[29, 2190.0],
			[52, 4490.0],
			[48, 3650.0],
			[56, 5990.0],
			[33, 2490.0],
			[54, 5490.0],
			[85, 59990.0],
			[22, 1690.0],
			[18, 1390.0],
			[4, 299.0],
			[53, 4990.0],
			[62, 8990.0],
			[36, 2690.0],
			[57, 6490.0],
			[69, 13490.0],
		]),
	],
	[
		"CNY",
		new Map([
			[17, 113.0],
			[41, 263.0],
			[14, 93.0],
			[20, 128.0],
			[30, 198.0],
			[49, 318.0],
			[38, 248.0],
			[74, 1448.0],
			[68, 1098.0],
			[77, 1648.0],
			[3, 18.0],
			[42, 268.0],
			[32, 218.0],
			[58, 588.0],
			[47, 298.0],
			[28, 188.0],
			[59, 618.0],
			[39, 253.0],
			[34, 228.0],
			[6, 40.0],
			[46, 288.0],
			[50, 328.0],
			[67, 1048.0],
			[81, 2998.0],
			[84, 4498.0],
			[12, 78.0],
			[23, 153.0],
			[25, 163.0],
			[5, 30.0],
			[7, 45.0],
			[78, 1998.0],
			[45, 283.0],
			[43, 273.0],
			[63, 818.0],
			[11, 73.0],
			[37, 243.0],
			[16, 108.0],
			[2, 12.0],
			[51, 348.0],
			[64, 848.0],
			[31, 208.0],
			[26, 168.0],
			[61, 698.0],
			[66, 998.0],
			[73, 1398.0],
			[8, 50.0],
			[79, 2298.0],
			[65, 898.0],
			[40, 258.0],
			[27, 178.0],
			[24, 158.0],
			[71, 1248.0],
			[83, 3998.0],
			[82, 3298.0],
			[13, 88.0],
			[72, 1298.0],
			[70, 1198.0],
			[19, 123.0],
			[76, 1598.0],
			[21, 138.0],
			[9, 60.0],
			[60, 648.0],
			[44, 278.0],
			[87, 6498.0],
			[35, 233.0],
			[86, 5898.0],
			[1, 6.0],
			[80, 2598.0],
			[10, 68.0],
			[75, 1498.0],
			[55, 488.0],
			[15, 98.0],
			[29, 193.0],
			[52, 388.0],
			[48, 308.0],
			[56, 518.0],
			[33, 223.0],
			[54, 448.0],
			[85, 4998.0],
			[22, 148.0],
			[18, 118.0],
			[4, 25.0],
			[53, 418.0],
			[62, 798.0],
			[36, 238.0],
			[57, 548.0],
			[69, 1148.0],
		]),
	],
	[
		"CAN",
		new Map([
			[17, 23.99],
			[41, 56.99],
			[14, 19.99],
			[20, 27.99],
			[30, 39.99],
			[49, 67.99],
			[38, 52.99],
			[74, 299.99],
			[68, 229.99],
			[77, 349.99],
			[3, 3.99],
			[42, 57.99],
			[32, 43.99],
			[58, 119.99],
			[47, 64.99],
			[28, 37.99],
			[59, 129.99],
			[39, 53.99],
			[34, 46.99],
			[6, 8.49],
			[46, 63.99],
			[50, 69.99],
			[67, 219.99],
			[81, 599.99],
			[84, 999.99],
			[12, 16.99],
			[23, 31.99],
			[25, 34.99],
			[5, 6.99],
			[7, 9.99],
			[78, 399.99],
			[45, 62.99],
			[43, 59.99],
			[63, 169.99],
			[11, 14.99],
			[37, 51.99],
			[16, 21.99],
			[2, 2.79],
			[51, 74.99],
			[64, 179.99],
			[31, 41.99],
			[26, 35.99],
			[61, 149.99],
			[66, 209.99],
			[73, 289.99],
			[8, 10.99],
			[79, 499.99],
			[65, 199.99],
			[40, 54.99],
			[27, 36.99],
			[24, 32.99],
			[71, 259.99],
			[83, 849.99],
			[82, 699.99],
			[13, 17.99],
			[72, 279.99],
			[70, 249.99],
			[19, 25.99],
			[76, 329.99],
			[21, 28.99],
			[9, 11.99],
			[60, 139.99],
			[44, 61.99],
			[87, 1399.99],
			[35, 47.99],
			[86, 1199.99],
			[1, 1.39],
			[80, 549.99],
			[10, 13.99],
			[75, 319.99],
			[55, 104.99],
			[15, 20.99],
			[29, 38.99],
			[52, 84.99],
			[48, 66.99],
			[56, 109.99],
			[33, 44.99],
			[54, 99.99],
			[85, 1099.99],
			[22, 29.99],
			[18, 24.99],
			[4, 5.49],
			[53, 89.99],
			[62, 159.99],
			[36, 49.99],
			[57, 114.99],
			[69, 239.99],
		]),
	],
	[
		"SEK",
		new Map([
			[17, 179.0],
			[41, 429.0],
			[14, 149.0],
			[20, 209.0],
			[30, 319.0],
			[49, 509.0],
			[38, 399.0],
			[74, 2295.0],
			[68, 1795.0],
			[77, 2595.0],
			[3, 30.0],
			[42, 439.0],
			[32, 339.0],
			[58, 949.0],
			[47, 489.0],
			[28, 289.0],
			[59, 995.0],
			[39, 409.0],
			[34, 359.0],
			[6, 65.0],
			[46, 479.0],
			[50, 519.0],
			[67, 1695.0],
			[81, 4795.0],
			[84, 7495.0],
			[12, 129.0],
			[23, 239.0],
			[25, 259.0],
			[5, 50.0],
			[7, 75.0],
			[78, 3195.0],
			[45, 469.0],
			[43, 449.0],
			[63, 1349.0],
			[11, 119.0],
			[37, 389.0],
			[16, 169.0],
			[2, 20.0],
			[51, 595.0],
			[64, 1395.0],
			[31, 329.0],
			[26, 269.0],
			[61, 1195.0],
			[66, 1595.0],
			[73, 2195.0],
			[8, 85.0],
			[79, 3695.0],
			[65, 1495.0],
			[40, 419.0],
			[27, 279.0],
			[24, 249.0],
			[71, 1995.0],
			[83, 6495.0],
			[82, 5495.0],
			[13, 139.0],
			[72, 2095.0],
			[70, 1895.0],
			[19, 199.0],
			[76, 2495.0],
			[21, 219.0],
			[9, 95.0],
			[60, 1095.0],
			[44, 459.0],
			[87, 10995.0],
			[35, 369.0],
			[86, 9495.0],
			[1, 10.0],
			[80, 4295.0],
			[10, 109.0],
			[75, 2395.0],
			[55, 795.0],
			[15, 159.0],
			[29, 299.0],
			[52, 649.0],
			[48, 499.0],
			[56, 849.0],
			[33, 349.0],
			[54, 749.0],
			[85, 8495.0],
			[22, 229.0],
			[18, 189.0],
			[4, 40.0],
			[53, 695.0],
			[62, 1295.0],
			[36, 379.0],
			[57, 895.0],
			[69, 1849.0],
		]),
	],
	[
		"AUD",
		new Map([
			[17, 26.99],
			[41, 64.99],
			[14, 21.99],
			[20, 30.99],
			[30, 46.99],
			[49, 77.99],
			[38, 59.99],
			[74, 349.99],
			[68, 259.99],
			[77, 399.99],
			[3, 4.49],
			[42, 65.99],
			[32, 49.99],
			[58, 139.99],
			[47, 72.99],
			[28, 42.99],
			[59, 149.99],
			[39, 60.99],
			[34, 52.99],
			[6, 9.99],
			[46, 71.99],
			[50, 79.99],
			[67, 249.99],
			[81, 699.99],
			[84, 1099.99],
			[12, 17.99],
			[23, 35.99],
			[25, 38.99],
			[5, 7.99],
			[7, 10.99],
			[78, 499.99],
			[45, 69.99],
			[43, 67.99],
			[63, 189.99],
			[11, 16.99],
			[37, 57.99],
			[16, 24.99],
			[2, 2.99],
			[51, 84.99],
			[64, 199.99],
			[31, 47.99],
			[26, 39.99],
			[61, 169.99],
			[66, 229.99],
			[73, 329.99],
			[8, 12.99],
			[79, 549.99],
			[65, 219.99],
			[40, 62.99],
			[27, 41.99],
			[24, 37.99],
			[71, 299.99],
			[83, 899.99],
			[82, 799.99],
			[13, 19.99],
			[72, 319.99],
			[70, 279.99],
			[19, 29.99],
			[76, 379.99],
			[21, 32.99],
			[9, 13.99],
			[60, 159.99],
			[44, 68.99],
			[87, 1599.99],
			[35, 54.99],
			[86, 1399.99],
			[1, 1.49],
			[80, 599.99],
			[10, 14.99],
			[75, 359.99],
			[55, 119.99],
			[15, 22.99],
			[29, 44.99],
			[52, 89.99],
			[48, 74.99],
			[56, 124.99],
			[33, 51.99],
			[54, 109.99],
			[85, 1249.99],
			[22, 34.99],
			[18, 27.99],
			[4, 5.99],
			[53, 99.99],
			[62, 179.99],
			[36, 55.99],
			[57, 129.99],
			[69, 269.99],
		]),
	],
]);

export function priceAtTier(tier: number, currency: string): number {
	return priceTiers.get(currency)?.get(tier) || 0.0;
}
