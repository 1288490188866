import { parseService } from "@/services/parse.service";
import { Cacheable, MILLIS_DAY } from "@/vault/Cacheable";
import { Indexable } from "../GPTypes";
import GPUser from "../GPUser";
import { ParseFile, ParseObject, ParseUser } from "../ParseTypes";

export default class GPTeam implements Indexable, Cacheable {
	id: string;
	name: string;
	uuid: string;
	teamUserID: string;
	avatarImg: ParseFile | null = null;
	capacity: number;
	cacheDate: Date;
	timeToLive: number;
	userDeleted: boolean;

	constructor(
		id: string,
		name: string,
		teamUserID: string,
		uuid: string,
		avatar: ParseFile | null = null,
		capacity: number,
		userDeleted: boolean,
		cacheDate = new Date(),
		timeToLive = MILLIS_DAY,		
	) {
		this.id = id;
		this.name = name;
		this.uuid = uuid;
		this.teamUserID = teamUserID;
		this.avatarImg = avatar;
		this.capacity = capacity;
		this.cacheDate = cacheDate;
		this.timeToLive = timeToLive;
		this.userDeleted = userDeleted;
	}

	public static clone(team: GPTeam): GPTeam {
		return new GPTeam(
			team.id,
			team.name,
			team.teamUserID,
			team.uuid,
			team.avatarImg,
			team.capacity,
			team.userDeleted,
			team.cacheDate,
			team.timeToLive
		);
	}

	public static async newFromParseObj(obj: ParseObject): Promise<GPTeam> {
		const id = obj.id;
		const name = obj.get("name");
		const teamUser = await obj.get("teamUser").fetch();
		const uuid = obj.get("uuid");
		const memberCap = obj.get("memberCapacity");
		const userDeleted = obj.get("userDeleted");
		return new GPTeam(id, name, teamUser.id, uuid, null, memberCap, userDeleted);
	}

	public async getParseUser(): Promise<ParseUser> {
		return await parseService.getUserByID(this.teamUserID);
	}

	public async getUserObject(): Promise<GPUser> {
		const user = await this.getParseUser();
		const gpuser = GPUser.fromParseUser(user);
		if (gpuser) {
			return gpuser;
		}
		throw new Error("Failed to get user object");
	}

	get avatarURL(): string {
		return GPTeam.getAvatarURL(this);
	}

	public static getAvatarURL(team?: GPTeam): string {
		if (team) {
			const avatarImgFile = team.avatarImg;
			if (avatarImgFile && avatarImgFile.url) {
				return avatarImgFile.url();
			}
		}
		return require("@/assets/images/img/default-team-img.png");
	}
}
