import GPTeam from "@/data/teams/GPTeam";
import { openDB, IDBPDatabase, DBSchema } from "idb";
import GPUser from "@/data/GPUser";
import GPTeamMember from "@/data/teams/GPTeamMember";
import GPTeamRole from "@/data/teams/GPTeamRole";
import { isCacheValid } from "./Cacheable";

interface AccountDB extends DBSchema {
	users: {
		key: string;
		value: GPUser;
		indexes: { id: string };
	};
	teams: {
		key: string;
		value: GPTeam;
		indexes: { id: string; uuid: string };
	};
	members: {
		key: string;
		value: GPTeamMember;
		indexes: { id: string; userID: string; teamID: string; roleID: string };
	};
	roles: {
		key: string;
		value: GPTeamRole;
		indexes: { id: string; teamID: string };
	};
}

const DB_NAME = "gp_account";

export class AccountVault {
	//db: Promise<IDBPDatabase<AccountDB>>;
	database: IDBPDatabase<AccountDB> | null;
	// teamMembers: Promise<IDBPDatabase<GPTeamMember>>;

	// cache: IDBDatabase | undefined;
	// cache: Promise<IDBPDatabase<unknown>>;

	constructor() {
		this.database = null;
		//we start fresh for each session
		// console.log("Deleting ", DB_NAME);
		// deleteDB(DB_NAME);
		// this.db = openDB(DB_NAME, 1, {
		// 	upgrade(db) {
		// 		console.log("upgrading ", DB_NAME);
		// 		// Setup user objectStore
		// 		const uos = db.createObjectStore("users", {
		// 			keyPath: "id",
		// 		});
		// 		uos.createIndex("id", "id", { unique: true });

		// 		// Setup teams objectStore
		// 		const tos = db.createObjectStore("teams", {
		// 			keyPath: "id",
		// 		});
		// 		tos.createIndex("id", "id", { unique: true });
		// 		tos.createIndex("uuid", "uuid", { unique: true });

		// 		// Setup members objectStore
		// 		const mos = db.createObjectStore("members", {
		// 			keyPath: "id",
		// 		});
		// 		mos.createIndex("id", "id", { unique: true });
		// 		mos.createIndex("userID", "userID");
		// 		mos.createIndex("teamID", "teamID");
		// 		mos.createIndex("roleID", "roleID");

		// 		// Setup roles objectStore
		// 		const ros = db.createObjectStore("roles", {
		// 			keyPath: "id",
		// 		});
		// 		ros.createIndex("id", "id", { unique: true });
		// 		ros.createIndex("teamID", "teamID");

		// 		console.log(`Database setup complete: ${DB_NAME}`);
		// 	},
		// });

		// console.log("db ", this.db);
	}

	public async db(): Promise<IDBPDatabase<AccountDB>> {
		// console.log("AccountDB database at start", this.database);
		if (this.database != null) {
			return this.database;
		}

		// console.log("Deleting ", DB_NAME);
		// await deleteDB(DB_NAME, {
		// 	blocked() {
		// 		console.log("Deletion is blocked!!!");
		// 	}

		// });
		// console.log("database deleted");
		// console.log("About to open account database");
		this.database = await openDB(DB_NAME, 1, {
			upgrade(db) {
				console.log("upgrading ", DB_NAME);
				// Setup user objectStore
				const uos = db.createObjectStore("users", {
					keyPath: "id",
				});
				uos.createIndex("id", "id", { unique: true });

				// Setup teams objectStore
				const tos = db.createObjectStore("teams", {
					keyPath: "id",
				});
				tos.createIndex("id", "id", { unique: true });
				tos.createIndex("uuid", "uuid", { unique: true });

				// Setup members objectStore
				const mos = db.createObjectStore("members", {
					keyPath: "id",
				});
				mos.createIndex("id", "id", { unique: true });
				mos.createIndex("userID", "userID");
				mos.createIndex("teamID", "teamID");
				mos.createIndex("roleID", "roleID");

				// Setup roles objectStore
				const ros = db.createObjectStore("roles", {
					keyPath: "id",
				});
				ros.createIndex("id", "id", { unique: true });
				ros.createIndex("teamID", "teamID");

				console.log(`Database setup complete: ${DB_NAME}`);
			},
		});
		// console.log("after open", this.database);

		await this.database.clear("users");
		await this.database.clear("teams");
		await this.database.clear("members");
		await this.database.clear("roles");

		// console.log("after clear", this.database);

		return this.database;
	} 

	// User caching
	public async fetchUser(id: string): Promise<GPUser | undefined> {
		console.log("fetchUser");
		const dbOp = await this.db();
		console.log("fetchUser has db");
		const user = await dbOp.get("users", id);
		console.log("fetchUser user", user);
		if (user) {
			const copy = new GPUser(user);
			if (isCacheValid(copy)) return copy;
			else dbOp.delete("users", id);
		}
	}

	public async cacheUser(user: GPUser): Promise<string> {
		const dbOP = await this.db();
		return dbOP.put("users", user);
	}

	async clearUserCache(): Promise<void> {
		const db = await this.db();
		await db.clear("users");
	}

	// Team caching
	async fetchTeam(id: string): Promise<GPTeam | undefined> {
		const dbOp = await this.db();
		const team = await dbOp.getFromIndex("teams", "id", id);
		if (team) {
			const clone = GPTeam.clone(team);
			if (isCacheValid(clone)) return clone;
			else dbOp.delete("teams", id);
		}
	}

	async fetchTeamWithUUID(uuid: string): Promise<GPTeam | undefined> {
		const dbOp = await this.db();
		const team = await dbOp.getFromIndex("teams", "uuid", uuid);
		if (team) {
			const clone = GPTeam.clone(team);
			if (isCacheValid(clone)) return clone;
			else dbOp.delete("teams", clone.id);
		}
	}

	async cacheTeam(team: GPTeam): Promise<string> {
		const dbOP = await this.db();
		team.cacheDate = new Date();
		return dbOP.put("teams", team);
	}

	async clearTeamCache(): Promise<void> {
		const db = await this.db();
		await db.clear("teams");
	}

	// Member caching
	async fetchMember(id: string): Promise<GPTeamMember | undefined> {
		const dbOp = await this.db();
		const member = await dbOp.get("members", id);
		if (member) {
			const clone = GPTeamMember.clone(member);
			if (isCacheValid(clone)) return clone;
			else dbOp.delete("members", id);
		}
	}

	async fetchMembersForUser(userID: string): Promise<GPTeamMember[]> {
		const dbOp = await this.db();
		const members = await dbOp.getAllFromIndex("members", "userID", userID);
		return members.map((member) => GPTeamMember.clone(member));
	}

	async fetchMembersForTeam(teamID: string): Promise<GPTeamMember[]> {
		const dbOp = await this.db();
		const members = await dbOp.getAllFromIndex("members", "teamID", teamID);
		return members.map((member) => GPTeamMember.clone(member));
	}

	async fetchTeamsForUser(userID: string): Promise<GPTeam[]> {
		const members = await this.fetchMembersForUser(userID);
		const teams: GPTeam[] = [];
		for (const member of members) {
			const team = await this.fetchTeam(member.teamID);
			if (team && member.isActive) {
				teams.push(GPTeam.clone(team));
			}
		}
		return teams;
	}

	async cacheMember(member: GPTeamMember): Promise<string> {
		const dbOP = await this.db();
		member.cacheDate = new Date();
		return dbOP.put("members", member);
	}

	async clearMemberCache(): Promise<void> {
		const db = await this.db();
		await db.clear("members");
	}

	// Role caching
	async fetchRole(id: string): Promise<GPTeamRole | undefined> {
		const dbOp = await this.db();
		const role = await dbOp.get("roles", id);
		if (role) {
			const copy = GPTeamRole.clone(role);
			if (isCacheValid(copy)) return copy;
			else dbOp.delete("roles", id);
		}
	}

	async fetchRolesForTeam(teamID: string): Promise<GPTeamRole[]> {
		const dbOp = await this.db();
		const roles = await dbOp.getAllFromIndex("roles", "teamID", teamID);
		return roles.map((role) => GPTeamRole.clone(role));
	}

	async cacheRole(role: GPTeamRole): Promise<string> {
		const dbOP = await this.db();
		role.cacheDate = new Date();
		return dbOP.put("roles", role);
	}

	async clearRoleCache(): Promise<void> {
		const db = await this.db();
		await db.clear("roles");
	}

	async clearAllCache(): Promise<void> {
		await this.clearUserCache();
		await this.clearTeamCache();
		await this.clearMemberCache();
		await this.clearRoleCache();
	}
}
// export const accountVault = new AccountVault();
