/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

function init(
	el: { setAttribute: (arg0: string, arg1: any) => void },
	binding: { arg: string; value: string }
) {
	const position = binding.arg || "top";
	const tooltipText = binding.value || "Tooltip text";
	el.setAttribute("position", position);
	el.setAttribute("tooltip", tooltipText);
}

// eslint-disable-next-line no-undef
function tooltipDirective(app: any) {
	app.directive("tooltip", {
		mounted(
			el: { setAttribute: (arg0: string, arg1: any) => void },
			binding: { arg: string; value: string }
		) {
			init(el, binding);
		},
		updated(
			el: { setAttribute: (arg0: string, arg1: any) => void },
			binding: { arg: string; value: string }
		) {
			init(el, binding);
		},
	});
}

export default tooltipDirective;
