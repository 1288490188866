import GPStoreContent from "./GPStoreContent";
import { ParseObject } from "../ParseTypes";
import { coreData } from "@/services/CoreData";
import { Cacheable, MILLIS_DAY } from "@/vault/Cacheable";

export default class GPStoreSection implements Cacheable {
	id: string;
	active?: boolean;
	activeConditions?: string;
	backgroundColor?: string;
	backgroundColorDarkMode?: string;
	backgroundGradiantDarkModeEndColor?: string;
	backgroundGradiantDarkModeStartColor?: string;
	backgroundGradiantStartColor?: string;
	backgroundGradientEndColor?: string;
	content?: string[];
	createdAt?: Date;
	endDate?: Date;
	iOS?: boolean;
	locale?: string;
	mac?: boolean;
	mapQuery?: string;
	maxVersion?: string;
	maxVersionMac?: string;
	messageActiveConditions?: string;
	minVersion?: string;
	minVersionMac?: string;
	numberRows?: number;
	pageId?: string;
	seeAlsoLink?: string;
	showSeeAllAtBottomOfSection?: boolean;
	sortOrder?: number;
	startaDate?: Date;
	testMode?: string;
	title?: string;
	titleColor?: string;
	type?: string;
	updatedAt?: Date;
	cacheDate: Date;
	timeToLive: number;

	constructor(parseProd?: ParseObject) {
		if (parseProd) {
			this.id = parseProd.id;
			this.active = parseProd.get("active");
			this.activeConditions = parseProd.get("activeConditions");
			this.backgroundColor = parseProd.get("backgroundColor");
			this.backgroundColorDarkMode = parseProd.get("backgroundColorDarkMode");
			this.backgroundGradiantDarkModeEndColor = parseProd.get(
				"backgroundGradiantDarkModeEndColor"
			);
			this.backgroundGradiantDarkModeStartColor = parseProd.get(
				"backgroundGradiantDarkModeStartColor"
			);
			this.backgroundGradiantStartColor = parseProd.get(
				"backgroundGradiantStartColor"
			);
			this.backgroundGradientEndColor = parseProd.get(
				"backgroundGradientEndColor"
			);
			if (parseProd.get("content") != null) {
				this.content = parseProd.get("content").map((cont: { id: string }) => {
					return cont.id;
				});
			}
			this.createdAt = parseProd.get("createdAt");
			this.endDate = parseProd.get("endDate");
			this.iOS = parseProd.get("iOS");
			this.locale = parseProd.get("locale");
			this.mac = parseProd.get("mac");
			this.mapQuery = parseProd.get("mapQuery");
			this.maxVersion = parseProd.get("maxVersion");
			this.maxVersionMac = parseProd.get("maxVersionMac");
			this.messageActiveConditions = parseProd.get("messageActiveConditions");
			this.minVersion = parseProd.get("minVersion");
			this.minVersionMac = parseProd.get("minVersionMac");
			this.numberRows = parseProd.get("numberRows");
			this.pageId = parseProd.get("pageaId");
			this.seeAlsoLink = parseProd.get("seeAlsoLink");
			this.showSeeAllAtBottomOfSection = parseProd.get(
				"showSeeAllAtBottomOfSection"
			);
			this.sortOrder = parseProd.get("sortOrder");
			this.startaDate = parseProd.get("startaDate");
			this.testMode = parseProd.get("testMode");
			this.title = parseProd.get("title");
			this.titleColor = parseProd.get("titleColor");
			this.type = parseProd.get("type");
			this.updatedAt = parseProd.get("updatedAt");
		} else {
			this.id = "obj" + Math.floor(Math.random() * 20000) + "rn";
		}
		this.cacheDate = new Date();
		this.timeToLive = MILLIS_DAY;
	}

	public static clone(obj: GPStoreSection): GPStoreSection {
		const copy = new GPStoreSection();
		copy.id = obj.id;
		copy.active = obj.active;
		copy.activeConditions = obj.activeConditions;
		copy.backgroundColor = obj.backgroundColor;
		copy.backgroundColorDarkMode = obj.backgroundColorDarkMode;
		copy.backgroundGradiantDarkModeEndColor =
			obj.backgroundGradiantDarkModeEndColor;
		copy.backgroundGradiantDarkModeStartColor =
			obj.backgroundGradiantDarkModeStartColor;
		copy.backgroundGradiantStartColor = obj.backgroundGradiantStartColor;
		copy.backgroundGradientEndColor = obj.backgroundGradientEndColor;
		copy.content = obj.content;
		copy.createdAt = obj.createdAt;
		copy.endDate = obj.endDate;
		copy.iOS = obj.iOS;
		copy.locale = obj.locale;
		copy.mac = obj.mac;
		copy.mapQuery = obj.mapQuery;
		copy.maxVersion = obj.maxVersion;
		copy.maxVersionMac = obj.maxVersionMac;
		copy.messageActiveConditions = obj.messageActiveConditions;
		copy.minVersion = obj.minVersion;
		copy.minVersionMac = obj.minVersionMac;
		copy.numberRows = obj.numberRows;
		copy.pageId = obj.pageId;
		copy.seeAlsoLink = obj.seeAlsoLink;
		copy.showSeeAllAtBottomOfSection = obj.showSeeAllAtBottomOfSection;
		copy.sortOrder = obj.sortOrder;
		copy.startaDate = obj.startaDate;
		copy.testMode = obj.testMode;
		copy.title = obj.title;
		copy.titleColor = obj.titleColor;
		copy.type = obj.type;
		copy.updatedAt = obj.updatedAt;
		copy.cacheDate = obj.cacheDate;
		copy.timeToLive = obj.timeToLive;
		return copy;
	}

	public async getLoadedContent(): Promise<GPStoreContent[]> {
		const retContent: GPStoreContent[] = [];
		if (this.content) {
			for (const contId of this.content) {
				// console.log(`getting loadedContent w/ id: ${contId}`);
				// const cont = await store.getters["gpstore/getContentById"](contId);
				const cont = await coreData.getStoreContentById(contId);
				// console.log("got loaded content:");
				// console.log(cont);
				if (cont !== undefined) retContent.push(cont);
			}
		}
		return retContent;
	}

	public static newFromContent(
		content: GPStoreContent[],
		id?: string
	): GPStoreSection {
		const gps = new GPStoreSection();
		gps.content = content.map((cont) => {
			return cont.id;
		});
		gps.type = "vertical";
		if (id) {
			gps.id = id;
		} else {
			gps.id =
				Math.random().toString(36).substring(2, 15) + new Date().getTime();
		}
		return gps;
	}

	public static compareSort(a: GPStoreSection, b: GPStoreSection): number {
		return (a.sortOrder || 0) - (b.sortOrder || 0);
	}
}
