/* eslint-disable no-prototype-builtins */
/* eslint-disable no-use-before-define */
// To parse this data:
//
//   import { Convert, WPPage } from "./file";
//
//   const wPPage = Convert.toWPPage(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface WPPost {
	id: number;
	date: Date;
	dateGmt: Date;
	guid: GUID;
	modified: Date;
	modifiedGmt: Date;
	slug: string;
	status: string;
	type: string;
	link: string;
	title: GUID;
	content: Content;
	excerpt: Content;
	author: number;
	featuredMedia: number;
	commentStatus: string;
	pingStatus: string;
	sticky: boolean;
	template: string;
	format: string;
	meta: any[];
	categories: number[];
	tags: any[];
	links: Links;
}

export interface WPPage {
	id: number;
	date: Date;
	dateGmt: Date;
	guid: GUID;
	modified: Date;
	modifiedGmt: Date;
	slug: string;
	status: string;
	type: string;
	link: string;
	title: GUID;
	content: Content;
	excerpt: Content;
	author: number;
	featuredMedia: number;
	parent: number;
	menuOrder: number;
	commentStatus: string;
	pingStatus: string;
	template: string;
	meta: any[];
	links: Links;
}

export interface Content {
	rendered: string;
	protected: boolean;
}

export interface GUID {
	rendered: string;
}

export interface Links {
	self: About[];
	collection: About[];
	about: About[];
	author: Author[];
	replies: Author[];
	versionHistory: VersionHistory[];
	predecessorVersion: PredecessorVersion[];
	wpAttachment: About[];
	wpTerm?: WpTerm[];
	curies: Cury[];
}

export interface About {
	href: string;
}

export interface Author {
	embeddable: boolean;
	href: string;
}

export interface Cury {
	name: string;
	href: string;
	templated: boolean;
}

export interface PredecessorVersion {
	id: number;
	href: string;
}

export interface VersionHistory {
	count: number;
	href: string;
}

export interface WpTerm {
	taxonomy: string;
	embeddable: boolean;
	href: string;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
	public static toWPPage(json: string): WPPage {
		return cast(JSON.parse(json), r("WPPage"));
	}

	public static wPPageToJson(value: WPPage): string {
		return JSON.stringify(uncast(value, r("WPPage")), null, 2);
	}

	public static toWPPost(json: string): WPPost {
		return cast(JSON.parse(json), r("WPPost"));
	}

	public static wPPostToJson(value: WPPost): string {
		return JSON.stringify(uncast(value, r("WPPost")), null, 2);
	}
}

function invalidValue(typ: any, val: any, key: any = ""): never {
	if (key) {
		throw Error(
			`Invalid value for key "${key}". Expected type ${JSON.stringify(
				typ
			)} but got ${JSON.stringify(val)}`
		);
	}
	throw Error(
		`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`
	);
}

function jsonToJSProps(typ: any): any {
	if (typ.jsonToJS === undefined) {
		const map: any = {};
		typ.props.forEach((p: any) => (map[p.json] = { key: p.js, typ: p.typ }));
		typ.jsonToJS = map;
	}
	return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
	if (typ.jsToJSON === undefined) {
		const map: any = {};
		typ.props.forEach((p: any) => (map[p.js] = { key: p.json, typ: p.typ }));
		typ.jsToJSON = map;
	}
	return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = ""): any {
	function transformPrimitive(typ: string, val: any): any {
		if (typeof typ === typeof val) return val;
		return invalidValue(typ, val, key);
	}

	function transformUnion(typs: any[], val: any): any {
		// val must validate against one typ in typs
		const l = typs.length;
		for (let i = 0; i < l; i++) {
			const typ = typs[i];
			try {
				return transform(val, typ, getProps);
			} catch (_) {
				// val did not validate
				console.log(_);
			}
		}
		return invalidValue(typs, val);
	}

	function transformEnum(cases: string[], val: any): any {
		if (cases.indexOf(val) !== -1) return val;
		return invalidValue(cases, val);
	}

	function transformArray(typ: any, val: any): any {
		// val must be an array with no invalid elements
		if (!Array.isArray(val)) return invalidValue("array", val);
		return val.map((el) => transform(el, typ, getProps));
	}

	function transformDate(val: any): any {
		if (val === null) {
			return null;
		}
		const d = new Date(val);
		if (isNaN(d.valueOf())) {
			return invalidValue("Date", val);
		}
		return d;
	}

	function transformObject(
		props: { [k: string]: any },
		additional: any,
		val: any
	): any {
		if (val === null || typeof val !== "object" || Array.isArray(val)) {
			return invalidValue("object", val);
		}
		const result: any = {};
		Object.getOwnPropertyNames(props).forEach((key) => {
			const prop = props[key];
			const v = Object.prototype.hasOwnProperty.call(val, key)
				? val[key]
				: undefined;
			result[prop.key] = transform(v, prop.typ, getProps, prop.key);
		});
		Object.getOwnPropertyNames(val).forEach((key) => {
			if (!Object.prototype.hasOwnProperty.call(props, key)) {
				result[key] = transform(val[key], additional, getProps, key);
			}
		});
		return result;
	}

	if (typ === "any") return val;
	if (typ === null) {
		if (val === null) return val;
		return invalidValue(typ, val);
	}
	if (typ === false) return invalidValue(typ, val);
	while (typeof typ === "object" && typ.ref !== undefined) {
		typ = typeMap[typ.ref];
	}
	if (Array.isArray(typ)) return transformEnum(typ, val);
	if (typeof typ === "object") {
		return typ.hasOwnProperty("unionMembers")
			? transformUnion(typ.unionMembers, val)
			: typ.hasOwnProperty("arrayItems")
			? transformArray(typ.arrayItems, val)
			: typ.hasOwnProperty("props")
			? transformObject(getProps(typ), typ.additional, val)
			: invalidValue(typ, val);
	}
	// Numbers can be parsed by Date but shouldn't be.
	if (typ === Date && typeof val !== "number") return transformDate(val);
	return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
	return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
	return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
	return { arrayItems: typ };
}

function u(...typs: any[]) {
	return { unionMembers: typs };
}

function o(props: any[], additional: any) {
	return { props, additional };
}

function m(additional: any) {
	return { props: [], additional };
}

function r(name: string) {
	return { ref: name };
}

const typeMap: any = {
	WPPage: o(
		[
			{ json: "id", js: "id", typ: 0 },
			{ json: "date", js: "date", typ: Date },
			{ json: "date_gmt", js: "dateGmt", typ: Date },
			{ json: "guid", js: "guid", typ: r("GUID") },
			{ json: "modified", js: "modified", typ: Date },
			{ json: "modified_gmt", js: "modifiedGmt", typ: Date },
			{ json: "slug", js: "slug", typ: "" },
			{ json: "status", js: "status", typ: "" },
			{ json: "type", js: "type", typ: "" },
			{ json: "link", js: "link", typ: "" },
			{ json: "title", js: "title", typ: r("GUID") },
			{ json: "content", js: "content", typ: r("Content") },
			{ json: "excerpt", js: "excerpt", typ: r("Content") },
			{ json: "author", js: "author", typ: 0 },
			{ json: "featured_media", js: "featuredMedia", typ: 0 },
			{ json: "parent", js: "parent", typ: 0 },
			{ json: "menu_order", js: "menuOrder", typ: 0 },
			{ json: "comment_status", js: "commentStatus", typ: "" },
			{ json: "ping_status", js: "pingStatus", typ: "" },
			{ json: "template", js: "template", typ: "" },
			{ json: "meta", js: "meta", typ: a("any") },
			{ json: "_links", js: "links", typ: r("Links") },
		],
		false
	),
	WPPost: o(
		[
			{ json: "id", js: "id", typ: 0 },
			{ json: "date", js: "date", typ: Date },
			{ json: "date_gmt", js: "dateGmt", typ: Date },
			{ json: "guid", js: "guid", typ: r("GUID") },
			{ json: "modified", js: "modified", typ: Date },
			{ json: "modified_gmt", js: "modifiedGmt", typ: Date },
			{ json: "slug", js: "slug", typ: "" },
			{ json: "status", js: "status", typ: "" },
			{ json: "type", js: "type", typ: "" },
			{ json: "link", js: "link", typ: "" },
			{ json: "title", js: "title", typ: r("GUID") },
			{ json: "content", js: "content", typ: r("Content") },
			{ json: "excerpt", js: "excerpt", typ: r("Content") },
			{ json: "author", js: "author", typ: 0 },
			{ json: "featured_media", js: "featuredMedia", typ: 0 },
			{ json: "comment_status", js: "commentStatus", typ: "" },
			{ json: "ping_status", js: "pingStatus", typ: "" },
			{ json: "sticky", js: "sticky", typ: true },
			{ json: "template", js: "template", typ: "" },
			{ json: "format", js: "format", typ: "" },
			{ json: "meta", js: "meta", typ: a("any") },
			{ json: "categories", js: "categories", typ: a(0) },
			{ json: "tags", js: "tags", typ: a("any") },
			{ json: "_links", js: "links", typ: r("Links") },
		],
		false
	),
	Content: o(
		[
			{ json: "rendered", js: "rendered", typ: "" },
			{ json: "protected", js: "protected", typ: true },
		],
		false
	),
	GUID: o([{ json: "rendered", js: "rendered", typ: "" }], false),
	Links: o(
		[
			{ json: "self", js: "self", typ: a(r("About")) },
			{ json: "collection", js: "collection", typ: a(r("About")) },
			{ json: "about", js: "about", typ: a(r("About")) },
			{ json: "author", js: "author", typ: a(r("Author")) },
			{ json: "replies", js: "replies", typ: a(r("Author")) },
			{
				json: "version-history",
				js: "versionHistory",
				typ: a(r("VersionHistory")),
			},
			{
				json: "predecessor-version",
				js: "predecessorVersion",
				typ: a(r("PredecessorVersion")),
			},
			{ json: "wp:attachment", js: "wpAttachment", typ: a(r("About")) },
			{ json: "wp:term", js: "wpTerm", typ: u(undefined, a(r("WpTerm"))) },
			{ json: "curies", js: "curies", typ: a(r("Cury")) },
		],
		false
	),
	About: o([{ json: "href", js: "href", typ: "" }], false),
	Author: o(
		[
			{ json: "embeddable", js: "embeddable", typ: true },
			{ json: "href", js: "href", typ: "" },
		],
		false
	),
	Cury: o(
		[
			{ json: "name", js: "name", typ: "" },
			{ json: "href", js: "href", typ: "" },
			{ json: "templated", js: "templated", typ: true },
		],
		false
	),
	PredecessorVersion: o(
		[
			{ json: "id", js: "id", typ: 0 },
			{ json: "href", js: "href", typ: "" },
		],
		false
	),
	VersionHistory: o(
		[
			{ json: "count", js: "count", typ: 0 },
			{ json: "href", js: "href", typ: "" },
		],
		false
	),

	WpTerm: o(
		[
			{ json: "taxonomy", js: "taxonomy", typ: "" },
			{ json: "embeddable", js: "embeddable", typ: true },
			{ json: "href", js: "href", typ: "" },
		],
		false
	),
};
