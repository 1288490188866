import { Indexable } from "@/data/GPTypes";
import GPUser from "@/data/GPUser";
import GPStoreContent from "@/data/store/GPStoreContent";
import GPStorePage from "@/data/store/GPStorePage";
import GPStoreSection from "@/data/store/GPStoreSection";
import GPTeam from "@/data/teams/GPTeam";
import GPTeamMember from "@/data/teams/GPTeamMember";
import GPTeamRole from "@/data/teams/GPTeamRole";
import { WPPage, WPPost } from "@/data/Wordpress";
import { AccountVault } from "@/vault/AccountVault";
import { StoreVault } from "@/vault/StoreVault";
import { WPVault } from "@/vault/WPVault";
import { Ref, ref } from "vue";
import { parseService } from "./parse.service";
import { wpservice } from "./wp.service";

// class Sync {
// 	private accountSync: AccountVault;

// 	constructor(): {};
// }

// class AccountStatus {
// 	loggedIn: boolean;
// 	currentUID?: string;
// 	accountSynced: boolean;

// 	constructor() {
// 		this.loggedIn = parseService.isLoggedIn();
// 		if (this.loggedIn) {
// 			this.currentUID = parseService.getCurrentUser()?.id;
// 		}
// 		this.accountSynced = false;
// 	}
// }

interface LiveHandler {
	updateResults: () => Promise<void>;
}

export class LiveQuery<T extends Indexable> implements LiveHandler {
	private results: Ref<T[]>;
	private query: (id: string) => Promise<T | T[]>;
	private queryID: string;

	constructor(
		queryID: string,
		queryFn: (id: string) => Promise<T | T[]>,
		initQueryFn?: (id: string) => Promise<T | T[]>
	) {
		this.query = queryFn;
		this.results = ref([]);
		this.queryID = queryID;
		this.init(initQueryFn);
	}

	private init(initQuery?: (id: string) => Promise<T | T[]>) {
		if (initQuery) {
			this.runQueryFn(initQuery);
		}
		this.updateResults();
	}

	async runQueryFn(fn: (id: string) => Promise<T | T[]>): Promise<void> {
		let vals: T[];
		const tmpVals = await fn(this.queryID);
		if (!Array.isArray(tmpVals)) {
			vals = [tmpVals];
		} else {
			vals = tmpVals;
		}
		for (const val of vals) {
			let foundMatch = false;
			for (const cachedVal of this.results.value) {
				if (val.id === cachedVal.id) {
					foundMatch = true;
					break;
				}
			}
			if (!foundMatch) {
				this.results.value.push(val);
			}
		}
	}

	async updateResults(): Promise<void> {
		await this.runQueryFn(this.query);
	}

	get subscribedResults(): Ref<T[]> {
		return this.results;
	}
}

class GPCoreData {
	private acctVault: AccountVault;
	private storeVault: StoreVault;
	private wpVault: WPVault;

	private liveUserQueries: LiveQuery<GPUser>[];
	private liveMemberQueries: Map<string, LiveQuery<GPTeamMember>>;
	private liveTeamQueries: Map<string, LiveQuery<GPTeam>>;
	private liveRoleQueries: LiveQuery<GPUser>[];

	constructor() {
		this.acctVault = new AccountVault();
		this.storeVault = new StoreVault();
		this.wpVault = new WPVault();
		this.liveUserQueries = [];
		this.liveMemberQueries = new Map();
		this.liveTeamQueries = new Map();
		this.liveRoleQueries = [];
	}

	public notifyAllLiveQueries() {
		const queries: LiveHandler[] = [];
		queries.concat(
			this.liveUserQueries,
			// this.liveTeamQueries,
			this.liveRoleQueries
		);
		for (const handler of queries) {
			handler.updateResults(); // TODO: should we await??
		}
	}

	public updateLiveQueries(type: string) {
		const liveQueries: LiveHandler[] = [];
		switch (type) {
			case "user":
				liveQueries.concat(this.liveUserQueries);
				break;
			case "member":
				this.liveMemberQueries.forEach((val) => liveQueries.push(val));
				// liveQueries.concat(this.liveMemberQueries);
				break;
			case "team":
				this.liveTeamQueries.forEach((val) => liveQueries.push(val));
				break;
			case "role":
				liveQueries.concat(this.liveRoleQueries);
				break;

			default:
				break;
		}
		for (const query of liveQueries) {
			query.updateResults();
		}
	}

	public async queryUsers() {
		this.updateLiveQueries("user");
	}

	queryMembers() {
		this.updateLiveQueries("member");
	}

	queryTeams() {
		this.updateLiveQueries("team");
	}

	public async getCurrentUser(): Promise<GPUser | undefined> {
		const curUser = parseService.getCurrentUser();
		console.log("getCurrentUser", curUser);
		if (curUser) {
			console.log("fetching user ", curUser.id);
			return this.getUser(curUser.id);
		}		
		return undefined;
	}

	public async getUser(id: string): Promise<GPUser | undefined> {
		const cached = await this.acctVault.fetchUser(id);
		console.log("cached", cached);
		if (cached) {
			return cached;
		}
		const parseUser = await parseService.getUserByID(id);
		console.log("parseUser", parseUser);
		const fetched = GPUser.fromParseUser(parseUser);
		console.log("fetched", fetched);
		if (fetched) {
			await this.acctVault.cacheUser(fetched);
		}
		return fetched;
	}

	// public async getUserGQL(id: string) {
	// 	const cached = await this.acctVault.fetchUser(id);
	// 	if (cached) {
	// 		return cached;
	// 	}
	// 	const parseUser = await parseService.getUserByID(id);
	// 	const fetched = GPUser.fromParseUser(parseUser);
	// 	if (fetched) {
	// 		await this.acctVault.cacheUser(fetched);
	// 	}
	// 	return fetched;
	// }

	public async getTeam(id: string): Promise<GPTeam | undefined> {
		const cached = await this.acctVault.fetchTeam(id);
		if (cached) {
			return cached;
		}
		const fetched = await parseService.getTeamByID(id);
		// const fetchedUser = GPTeam.newFromParseObj(parseTeam);
		if (fetched) {
			await this.acctVault.cacheTeam(fetched);
		}
		return fetched;
	}

	public async getTeamUUID(uuid: string): Promise<GPTeam | undefined> {
		const cached = await this.acctVault.fetchTeamWithUUID(uuid);
		if (cached) {
			return cached;
		}
		const fetched = await parseService.getTeamByUUID(uuid);
		// const fetchedUser = GPTeam.newFromParseObj(parseTeam);
		if (fetched) {
			await this.acctVault.cacheTeam(fetched);
		}
		return fetched;
	}

	private async getTeamsForUserRemote(userID: string): Promise<GPTeam[]> {
		const fetched = await parseService.getTeamMemberInfo(userID);
		if (fetched) {
			const teams: GPTeam[] = [];
			for (const member of fetched) {
				const team = await this.getTeam(member.teamID);
				if (team && member.isActive && !team.userDeleted) {
					teams.push(team);
					this.acctVault.cacheTeam(team);
				}
			}
			return teams;
		}
		return [];
	}

	public async getTeamsForUser(userID: string): Promise<GPTeam[] | undefined> {
		this.queryMembers();
		const cached = await this.acctVault.fetchTeamsForUser(userID);
		if (cached.length > 0) {
			return cached;
		}
		return this.getTeamsForUserRemote(userID);
	}

	public getTeamsForUserLive(userID: string): LiveQuery<GPTeam> {
		const curHandler = this.liveTeamQueries.get(userID);
		if (curHandler) {
			return curHandler;
		} else {
			const ftuserBind = this.acctVault.fetchTeamsForUser.bind(this.acctVault);
			const remoteFtUserBind = this.getTeamsForUserRemote.bind(this);
			const liveQuery = new LiveQuery<GPTeam>(
				userID,
				remoteFtUserBind,
				ftuserBind
			);
			this.liveTeamQueries.set(userID, liveQuery);
			return liveQuery;
		}
	}

	public async getMember(id: string): Promise<GPTeamMember | undefined | null> {
		this.queryMembers();
		const cached = await this.acctVault.fetchMember(id);
		if (cached) {
			return cached;
		}
		const fetched = await parseService.getMemberByID(id);
		// const fetchedUser = GPTeam.newFromParseObj(parseTeam);
		if (fetched) {
			await this.acctVault.cacheMember(fetched);
		}
		return fetched;
	}

	private async getMembersForTeamRemote(
		teamID: string
	): Promise<GPTeamMember[]> {
		const fetched = await parseService.getMembersForTeam(teamID);
		for (const member of fetched) {
			this.acctVault.cacheMember(member);
		}
		return fetched;
	}

	public async getMembersForTeam(teamID: string): Promise<GPTeamMember[]> {
		this.queryMembers();
		const cached = await this.acctVault.fetchMembersForTeam(teamID);
		if (cached.length > 0) {
			return cached;
		}
		return this.getMembersForTeamRemote(teamID);
	}

	public getMembersForTeamLive(teamID: string): LiveQuery<GPTeamMember> {
		const curHandler = this.liveMemberQueries.get(teamID);
		if (curHandler) {
			return curHandler;
		} else {
			const getMembRemoteBind = this.getMembersForTeamRemote.bind(this);
			const getMembCacheBind = this.acctVault.fetchMembersForTeam.bind(
				this.acctVault
			);
			const liveQuery = new LiveQuery<GPTeamMember>(
				teamID,
				getMembRemoteBind,
				getMembCacheBind
			);
			this.liveMemberQueries.set(teamID, liveQuery);
			return liveQuery;
		}
	}

	// public getMembersForTeamUUIDLive(teamUUID: string): LiveQuery<GPTeamMember> {
	// 		const curHandler = this.liveMemberQueries.get(teamID);
	// 		if (curHandler) {
	// 			return curHandler;
	// 		} else {
	// 			const getMembRemoteBind = this.getMembersForTeamRemote.bind(this);
	// 			const getMembCacheBind = this.acctVault.fetchMembersForTeam.bind(
	// 				this.acctVault
	// 			);
	// 			const liveQuery = new LiveQuery<GPTeamMember>(
	// 				teamID,
	// 				getMembRemoteBind,
	// 				getMembCacheBind
	// 			);
	// 			this.liveMemberQueries.set(teamID, liveQuery);
	// 			return liveQuery;
	// 		}
	// 	}
	// }

	public async getAllMembers(): Promise<GPTeamMember[]> {
		return (await this.acctVault.db()).getAll("members");
	}

	public async getRole(id: string): Promise<GPTeamRole | undefined> {
		const cached = await this.acctVault.fetchRole(id);
		if (cached) {
			return cached;
		}
		const fetched = await parseService.getRoleByID(id);
		// const fetchedUser = GPTeam.newFromParseObj(parseTeam);
		if (fetched) {
			await this.acctVault.cacheRole(fetched);
		}
		return fetched;
	}

	public async getRolesForTeam(teamID: string): Promise<GPTeamRole[]> {
		let retVal: GPTeamRole[] = [];
		// const cached = await this.acctVault.fetchRolesForTeam(teamID);
		// if (cached.length > 0) {
		// 	retVal = cached;
		// 	// return cached;
		// }
		const fetched = await parseService.getRolesForTeam(teamID);
		if (fetched) {
			for (const role of fetched) {
				this.acctVault.cacheRole(role);
			}
		}
		retVal = fetched;
		return retVal;
	}

	public async clearCache(): Promise<void> {
		return this.acctVault.clearAllCache();
	}

	public async updateTeamMember(member: GPTeamMember): Promise<void> {
		await parseService.updateMemberRole(member);
		await this.acctVault.cacheMember(member);
	}

	public async getStorePage(pageId: string): Promise<GPStorePage | undefined> {
		console.log(`getStorePage(pageId: ${pageId})`);
		// const cached = await this.storeVault.fetchPage(pageId);
		// if (cached) {
		// 	console.log("getStorePage: returning cached page");

		// 	return cached;
		// }
		console.log("gpStorePage: fetching from parse");

		const fetched = await parseService.getStorePage(pageId);
		if (fetched) {
			const storePage = GPStorePage.newFromPFO(fetched);
			console.log("Fetched from parse!", storePage);
			// this.storeVault.cachePage(storePage);
			return storePage;
		}
		console.log("Failed to fetch, returning undefined");
	}

	public async getStoreSection(
		sectionId: string
	): Promise<GPStoreSection | undefined> {
		// const cached = await this.storeVault.fetchSection(sectionId);
		// if (cached) {
		// 	return cached;
		// }
		const fetched = await parseService.getStoreSection(sectionId);
		if (fetched) {
			const storeSection = new GPStoreSection(fetched);
			// this.storeVault.cacheSection(storeSection);
			return storeSection;
		}
	}

	public async getStoreSectionsForPage(
		pageId: string
	): Promise<GPStoreSection[]> {
		// const cached = await this.storeVault.fetchSectionsForPage(pageId);
		// if (cached.length > 0) {
		// 	return cached;
		// }
		const fetched = await parseService.getStorePageSections(pageId);
		if (fetched) {
			const sections = [];
			for (const section of fetched) {
				const storeSection = new GPStoreSection(section);
				sections.push(storeSection);
				// this.storeVault.cacheSection(storeSection);
			}
			return sections;
		}
		return [];
	}

	public async getStoreContentById(
		contentId: string
	): Promise<GPStoreContent | undefined> {
		// const cached = await this.storeVault.fetchContentById(contentId);
		// if (cached) {
		// 	return cached;
		// }
		const fetched = await parseService.getStoreContent(contentId);
		if (fetched) {
			const storeContent = new GPStoreContent(fetched[0]);
			// this.storeVault.cacheContent(storeContent);
			return storeContent;
		}
	}

	public async getStoreContent(
		productId: string
	): Promise<GPStoreContent | undefined> {
		// const cached = await this.storeVault.fetchContent(productId);
		// if (cached) {
		// 	return cached;
		// }
		const fetched = await parseService.getStoreProduct(productId);
		if (fetched) {
			const storeContent = new GPStoreContent(fetched);
			// this.storeVault.cacheContent(storeContent);
			return storeContent;
		}
	}

	public async getWPPost(slug: string): Promise<WPPost | undefined> {
		const cached = await this.wpVault.fetchPost(slug);
		if (cached) {
			return cached;
		}
		const fetched = await wpservice.getPostFromSlug(slug);
		if (fetched) {
			this.wpVault.cachePost(fetched);
			return fetched;
		}
	}

	public async getWPPage(slug: string): Promise<WPPage | undefined> {
		const cached = await this.wpVault.fetchPage(slug);
		if (cached) {
			return cached;
		}
		const fetched = await wpservice.getPageFromSlug(slug);
		if (fetched) {
			this.wpVault.cachePage(fetched);
			return fetched;
		}
	}

	public async getWPRecent(): Promise<Array<WPPost | WPPage>> {
		const posts: Array<WPPost | WPPage> = [];
		const cachedPosts = await this.wpVault.getAllPosts();
		if (cachedPosts.length > 0) {
			posts.push(...cachedPosts);
		} else {
			const fetched = await wpservice.getRecentPosts();
			fetched.forEach(this.wpVault.cachePost);
			posts.push(...fetched);
		}
		const cachedPages = await this.wpVault.getAllPages();
		if (cachedPages.length > 0) {
			posts.push(...cachedPosts);
		} else {
			const fetched2 = await wpservice.getRecentPages();
			fetched2.forEach(this.wpVault.cachePage);
			posts.push(...fetched2);
		}
		return posts;
	}

	public async getWPFromSlug(
		slug: string
	): Promise<WPPost | WPPage | undefined> {
		// const cached = await this.wpVault.fetchPost(slug);
		// if (cached) {
		// 	return cached;
		// }
		const fetched = await wpservice.getPostFromSlug(slug);
		if (fetched) {
			// const wpPost = new GPSWPPost(fetched);
			// this.wpVault.cachePost(fetched);
			return fetched;
		}
		// const cached2 = await this.wpVault.fetchPage(slug);
		// if (cached2) {
		// 	return cached2;
		// }
		const fetched2 = await wpservice.getPageFromSlug(slug);
		if (fetched2) {
			// const wpPost = new GPSWPPost(fetched);
			// this.wpVault.cachePage(fetched2);
			return fetched2;
		}
	}
}

export const coreData = new GPCoreData();
