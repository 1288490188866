
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    img: {
      type: String,
      default: "https://designvault.io/wp-content/uploads/2020/06/05-1-600x1298.png",
    },
  },
});
