import GPStoreContent from "@/data/store/GPStoreContent";
import GPStorePage from "@/data/store/GPStorePage";
import GPStoreSection from "@/data/store/GPStoreSection";
import { DBSchema, IDBPDatabase, openDB, deleteDB } from "idb";
import { isCacheValid } from "./Cacheable";

interface StoreDB extends DBSchema {
	pages: {
		key: string;
		value: GPStorePage;
		indexes: { identifier: string };
	};
	sections: {
		key: string;
		value: GPStoreSection;
		indexes: { id: string; pageId: string };
	};
	content: {
		key: string;
		value: GPStoreContent;
		indexes: { _id: string; productId: string };
	};
}

const DB_NAME = "gp_store";

export class StoreVault {
	db: Promise<IDBPDatabase<StoreDB>>;

	constructor() {
		//We always delete the database at the start of a new session
		console.log("StoreVault Constructor");
		deleteDB(DB_NAME);
		console.log("deleted ", DB_NAME);
		this.db = openDB(DB_NAME, 1, {
			upgrade(db) {			
				
				console.log("upgrading ", DB_NAME);

				
				// Setup pages objectStore
				const uos = db.createObjectStore("pages", {
					keyPath: "identifier",
				});
				uos.createIndex("identifier", "identifier", { unique: true });

				// Setup sections objectStore
				const tos = db.createObjectStore("sections", {
					keyPath: "id",
				});
				tos.createIndex("id", "id", { unique: true });
				tos.createIndex("pageId", "pageId", { unique: false });

				// Setup content objectStore
				const mos = db.createObjectStore("content", {
					keyPath: "_id",
				});
				mos.createIndex("_id", "_id", { unique: true });
				mos.createIndex("productId", "productId", { unique: true });

				console.log(`Database setup complete: ${DB_NAME}`);
			},
		});
	}

	public async fetchPage(identifier: string): Promise<GPStorePage | undefined> {
		console.log("fetchPage ", identifier);

		const dbOp = await this.db;
		console.log("got dbOp", identifier);
		const page = await dbOp.get("pages", identifier);

		console.log("Fetched page", identifier);
		if (page) {
			const cloned = GPStorePage.clone(page);
			if (isCacheValid(cloned)) return cloned;
			else dbOp.delete("pages", identifier);
		}
	}

	public async cachePage(page: GPStorePage): Promise<void> {
		const dbOp = await this.db;
		page.cacheDate = new Date();
		await dbOp.put("pages", GPStorePage.clone(page));
	}

	public async fetchSection(id: string): Promise<GPStoreSection | undefined> {
		const dbOp = await this.db;
		const section = await dbOp.get("sections", id);
		if (section) {
			const cloned = GPStoreSection.clone(section);
			if (isCacheValid(cloned)) return cloned;
			else dbOp.delete("sections", id);
		}
	}

	public async cacheSection(section: GPStoreSection): Promise<void> {
		const dbOp = await this.db;
		section.cacheDate = new Date();
		await dbOp.put("sections", GPStoreSection.clone(section));
	}

	public async fetchSectionsForPage(pageId: string): Promise<GPStoreSection[]> {
		const dbOp = await this.db;
		const sections = await dbOp.getAll("sections", pageId);
		return sections
			.filter((sect) => {
				const valid = isCacheValid(sect);
				if (!valid) dbOp.delete("sections", sect.id);
				return valid;
			})
			.map((sect) => GPStoreSection.clone(sect));
	}

	public async fetchContent(
		productId: string
	): Promise<GPStoreContent | undefined> {
		const dbOp = await this.db;
		const content = await dbOp.getFromIndex("content", "productId", productId);
		if (content) {
			const clone = GPStoreContent.clone(content);
			if (isCacheValid(clone)) return clone;
			else dbOp.delete("content", clone._id);
		}
	}

	public async fetchContentById(
		contentId: string
	): Promise<GPStoreContent | undefined> {
		const dbOp = await this.db;
		const content = await dbOp.getFromIndex("content", "_id", contentId);
		if (content) {
			const clone = GPStoreContent.clone(content);
			if (isCacheValid(clone)) return clone;
			else dbOp.delete("content", clone._id);
		}
	}

	public async cacheContent(content: GPStoreContent): Promise<void> {
		const dbOp = await this.db;
		content.cacheDate = new Date();
		await dbOp.put("content", GPStoreContent.clone(content));
	}
}
