//import { GPMapWaypoint } from './GPMapWaypoint';
import { ParseObject } from "./ParseTypes";

export class GPMapWaypoint {
  id: string;
  uuid: string;
  title: string;
  latitude: number;
  longitude: number;
  private icon: string;
  note: string;
  syncTimestamp?: number;
  iconColor?: string;
  iconType?: number;
  showTitleOnMap: boolean;
  titlePosition: number;

  get iconAnchor(): string {

    //Thes conditions come from GPMapWaypoint.m in the iOS App.
    
    if (this.iconType == 11 || //GPMapWaypointTypeBubble ||
        this.iconType == 9  || //GPMapWaypointTypeBubbleSmall ||
        this.iconType == 10 || //GPMapWaypointTypeBubbleMedium ||
        this.iconType == 12 || //GPMapWaypointTypeBubbleLarge ||
        this.iconType == 17 || //GPMapWaypointTypeBubbleInverted ||
        this.iconType == 15 || //GPMapWaypointTypeBubbleInvertedSmall ||
        this.iconType == 16 || //GPMapWaypointTypeBubbleInvertedMedium ||
        this.iconType == 18 || //GPMapWaypointTypeBubbleInvertedLarge ||
        this.iconType == 29 || //GPMapWaypointTypeBubbleRenderedImage ||
        this.iconType == 27 || //GPMapWaypointTypeBubbleRenderedImageSmall ||
        this.iconType == 28 || //GPMapWaypointTypeBubbleRenderedImageMedium ||
        this.iconType == 30 || //GPMapWaypointTypeBubbleRenderedImageLarge ||
        this.iconType == 54 || //GPMapWaypointTypeEmojiBubble ||
        this.iconType == 52 || //GPMapWaypointTypeEmojiBubbleSmall ||
        this.iconType == 53 || //GPMapWaypointTypeEmojiBubbleMedium ||
        this.iconType == 55 || //GPMapWaypointTypeEmojiBubbleLarge ||
        this.iconType == 58 || //GPMapWaypointTypeEmojiBubbleInverted ||
        this.iconType == 56 || //GPMapWaypointTypeEmojiBubbleInvertedSmall ||
        this.iconType == 57 || //GPMapWaypointTypeEmojiBubbleInvertedMedium ||
        this.iconType == 59) { //GPMapWaypointTypeEmojiBubbleInvertedLarge ||

          //Technically this is .04% of the way up from the bottom
          //but we are just going to pin it to the bottom
          return "bottom";
    }

  
    if (this.iconType == 13 || //GPMapWaypointTypePointer ||
        this.iconType == 42 || //GPMapWaypointTypePointerSmall ||
        this.iconType == 43 || //GPMapWaypointTypePointerMedium ||
        this.iconType == 44 || //GPMapWaypointTypePointerLarge ||
        this.iconType == 14 || //GPMapWaypointTypeMapPin ||
        this.iconType == 45 || //GPMapWaypointTypeMapPinSmall ||
        this.iconType == 46 || //GPMapWaypointTypeMapPinMedium ||
        this.iconType == 47 ) { //GPMapWaypointTypeMapPinLarge)
    
        return "bottom";
    }
  
    let iconName = "";
    if (this.icon) {
      iconName = this.icon;
      iconName = iconName.replace(".png", "");
      iconName = iconName.replace(".jpg", "");
      iconName = iconName.replace(".jpeg", "");
    }

    const pointerIconNames = [
      "waypointGreen", 
      "waypointBlue", 
      "waypointOrange", 
      "waypointRed", 
      "waypointBrown",
      "markerGreen", 
      "markerBlue", 
      "markerOrange", 
      "MapRouteIcon", 
      "MarkerBrown", 
      "waypointRenderedPinColorGeneric",
      "FP_AerialHazard",
      "FP_Lookout"
    ];

    if (pointerIconNames.includes(iconName)) {
      return "bottom";
    }

    return "center";
  }


  get iconTitleAnchor(): string {
    if (this.titlePosition == 1) { //Position on the left
      return "right";  //So we anchor to the right
    } else if (this.titlePosition == 2) { //Position on the right
      return "left";  //So we anchor to the left
    } else if (this.titlePosition == 3) { //Position on the top
      return "bottom";  //So we anchor to the bottom
    } else if (this.titlePosition == 4) { //Position on the bottom
      return "top";  //So we anchor to the top
    }
    return "bottom";
  }

  get iconTitleOffset(): number[] {
    const iconSize = this.iconSize;
    const iconHeight = iconSize[1];
    const iconWidth = iconSize[0];
    const textSize = 18.0; //This is set on MapLibre style layer
    const padding = 3;

    //We have to return an array of [x-offset,y-offset] and they
    //are measured in em.  So 1em is the text size. 

    if (this.titlePosition == 1) { //Position on the left
      if (this.iconAnchor == "bottom") {
        return [-((iconWidth/2.0) + padding)/textSize, -(iconHeight/2.0)/textSize];
      }
      return [-((iconWidth/2.0) + padding)/textSize, 0];
    } else if (this.titlePosition == 2) { //Position on the right
      if (this.iconAnchor == "bottom") {
        return [((iconWidth/2.0) + padding)/textSize, -(iconHeight/2.0)/textSize];
      }
      return [((iconWidth/2.0) + padding)/textSize, 0];
    } else if (this.titlePosition == 3) { //Position on the top
      if (this.iconAnchor == "bottom") {
        return [0, -(iconHeight + padding)/textSize];
      }
      return [0, -((iconHeight/2.0) + padding)/textSize];
    } else if (this.titlePosition == 4) { //Position on the bottom
      if (this.iconAnchor == "bottom") {
        return [0, padding/textSize];
      }
      return [0, ((iconHeight/2.0) + padding)/textSize];
    }

    if (this.iconAnchor == "bottom") {
      return [0, -(iconHeight + padding)/textSize];
    }
    return [0, -((iconHeight/2.0) + padding)/textSize];

  }

  get iconSize(): number[] {
    switch (this.iconType) {

      case 0: return [30, 30]; //GPMapWaypointTypeNormal,                    //0
      case 1: return [28, 28]; //GPMapWaypointTypeButton,                    //1
      case 2: return [16, 16]; //GPMapWaypointTypeButtonSmall,               //2
      case 3: return [34, 34]; //GPMapWaypointTypeButtonSquareRounded,       //3
      case 4: return [26, 26]; //GPMapWaypointTypeButtonSquareRoundedSmall,  //4
      case 5: return [30, 30]; //GPMapWaypointTypeButtonSquareRoundedMedium, //5
      case 6: return [40, 40]; //GPMapWaypointTypeButtonSquareRoundedLarge,  //6
      case 7: return [22, 22]; //GPMapWaypointTypeButtonMedium,              //7
      case 8: return [40, 40]; //GPMapWaypointTypeButtonLarge,               //8
      case 9: return [24, 24]; //GPMapWaypointTypeBubbleSmall,               //9
      case 10: return [28, 28]; //GPMapWaypointTypeBubbleMedium,              //10
      case 11: return [40, 40]; //GPMapWaypointTypeBubble,                    //11
      case 12: return [60, 60]; //GPMapWaypointTypeBubbleLarge,               //12
      case 13: return [44, 44]; //GPMapWaypointTypePointer,                   //13
      case 14: return [44, 44]; //GPMapWaypointTypeMapPin,                    //14
      case 15: return [24, 24]; //GPMapWaypointTypeBubbleInvertedSmall,       //15
      case 16: return [28, 28]; //GPMapWaypointTypeBubbleInvertedMedium,      //16
      case 17: return [40, 40]; //GPMapWaypointTypeBubbleInverted,            //17
      case 18: return [60, 60]; //GPMapWaypointTypeBubbleInvertedLarge,       //18
      case 19: return [16, 16]; //GPMapWaypointTypeButtonInvertedSmall,       //19
      case 20: return [22, 22]; //GPMapWaypointTypeButtonInvertedMedium,      //20
      case 21: return [28, 28]; //GPMapWaypointTypeButtonInverted,            //21
      case 22: return [40, 40]; //GPMapWaypointTypeButtonInvertedLarge,       //22
      case 23: return [34, 34]; //GPMapWaypointTypeButtonSquareRoundedInverted, //23
      case 24: return [26, 26]; //GPMapWaypointTypeButtonSquareRoundedInvertedSmall, //24
      case 25: return [30, 30]; //GPMapWaypointTypeButtonSquareRoundedInvertedMedium, //25
      case 26: return [40, 40]; //GPMapWaypointTypeButtonSquareRoundedInvertedLarge,  //26
      case 27: return [30, 30]; //GPMapWaypointTypeBubbleRenderedImageSmall,          //27
      case 28: return [50, 50]; //GPMapWaypointTypeBubbleRenderedImageMedium,         //28
      case 29: return [60, 60]; //GPMapWaypointTypeBubbleRenderedImage,               //29
      case 30: return [80, 80]; //GPMapWaypointTypeBubbleRenderedImageLarge,          //30
      case 31: return [16, 16]; //GPMapWaypointTypeNormalSmall,                       //31
      case 32: return [24, 24]; //GPMapWaypointTypeNormalMedium,                      //32
      case 33: return [44, 44]; //GPMapWaypointTypeNormalLarge,                       //33
      case 34: return [16, 16]; //GPMapWaypointTypeButtonFilledSmall,                 //34
      case 35: return [22, 22]; //GPMapWaypointTypeButtonFilledMedium,                //35
      case 36: return [28, 28]; //GPMapWaypointTypeButtonFilled,                      //36
      case 37: return [40, 40]; //GPMapWaypointTypeButtonFilledLarge,                 //37
      case 38: return [30, 30]; //GPMapWaypointTypeCircleRenderedImageSmall,          //38
      case 39: return [45, 45]; //GPMapWaypointTypeCircleRenderedImageMedium,         //39
      case 40: return [60, 60]; //GPMapWaypointTypeCircleRenderedImage,               //40
      case 41: return [80, 80]; //GPMapWaypointTypeCircleRenderedImageLarge,          //41
      case 42: return [16, 16]; //GPMapWaypointTypePointerSmall,                      //42
      case 43: return [24, 24]; //GPMapWaypointTypePointerMedium,                     //43
      case 44: return [44, 44]; //GPMapWaypointTypePointerLarge,                      //44
      case 45: return [16, 16]; //GPMapWaypointTypeMapPinSmall,                       //45
      case 46: return [24, 24]; //GPMapWaypointTypeMapPinMedium,                      //46
      case 47: return [44, 44]; //GPMapWaypointTypeMapPinLarge,                       //47
      case 48: return [16, 16]; //GPMapWaypointTypeEmojiSmall,                        //48
      case 49: return [22, 22]; //GPMapWaypointTypeEmojiMedium,                       //49
      case 50: return [28, 28]; //GPMapWaypointTypeEmoji,                             //50
      case 51: return [40, 40]; //GPMapWaypointTypeEmojiLarge,                        //51
      case 52: return [24, 24]; //GPMapWaypointTypeEmojiBubbleSmall,                  //52
      case 53: return [28, 28]; //GPMapWaypointTypeEmojiBubbleMedium,                 //53
      case 54: return [40, 40]; //GPMapWaypointTypeEmojiBubble,                       //54
      case 55: return [60, 60]; //GPMapWaypointTypeEmojiBubbleLarge,                  //55
      case 56: return [24, 24]; //GPMapWaypointTypeEmojiBubbleInvertedSmall,          //56
      case 57: return [28, 28]; //GPMapWaypointTypeEmojiBubbleInvertedMedium,         //57
      case 58: return [40, 40]; //GPMapWaypointTypeEmojiBubbleInverted,               //58
      case 59: return [60, 60]; //GPMapWaypointTypeEmojiBubbleInvertedLarge,          //59
      case 60: return [30, 30]; //GPMapWaypointTypeEmojiCircleSmall,                  //60
      case 61: return [45, 45]; //GPMapWaypointTypeEmojiCircleMedium,                 //61
      case 62: return [60, 60]; //GPMapWaypointTypeEmojiCircle,                       //62
      case 63: return [80, 80]; //GPMapWaypointTypeEmojiCircleLarge                   //63
  
    }
    return [28, 28];
  }

  get fullIconName(): string {

    let iconName = "";
    if (this.icon) {
      iconName = this.icon;
      iconName = iconName.replace(".png", "");
      iconName = iconName.replace(".jpg", "");
      iconName = iconName.replace(".jpeg", "");
      iconName = encodeURIComponent(iconName);
    }

    if (this.iconColor) {
      const color = this.iconColor.replace("#", "");
      iconName = iconName + "--" + color;
    }

    if (this.iconType) {
      iconName = iconName + "--" + this.iconType;
    } else {
      iconName = iconName + "--0";
    }

    return iconName;
  }

  get iconURL(): string {
    const iconName = this.fullIconName;
    const url =  `https://s3-us-west-2.amazonaws.com/gp.us.waypointicons/${iconName}@2x.png`;
    return url;
  }

  constructor(
    id: string,
    uuid: string,
    title: string,
    latitude: number,
    longitude: number,
    icon: string,
    note: string,
    iconColor: string,
    iconType: number,
    showTitleOnMap: boolean,
    titlePosition: number
  ) {
    this.id = id;
    this.title = title;    
    this.uuid = uuid;
    this.latitude = latitude;
    this.longitude = longitude;
    this.icon = icon;
    this.note = note;
    this.iconColor = iconColor;
    this.iconType = iconType;
    this.showTitleOnMap = showTitleOnMap;
    this.titlePosition = titlePosition;
  }
  
  static async fromParseObject(parseObject: ParseObject): Promise<GPMapWaypoint> {
    return new GPMapWaypoint(
      parseObject.id,
      parseObject.get("uuid"),
      parseObject.get("title"),
      parseObject.get("latitude"),
      parseObject.get("longitude"),
      parseObject.get("icon"),
      parseObject.get("note"),
      parseObject.get("iconColor"),
      parseObject.get("iconType"),
      parseObject.get("showTitleOnMap"),
      parseObject.get("titlePosition")
    );
  }
}
