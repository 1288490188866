import { Cacheable, MILLIS_DAY } from "@/vault/Cacheable";
import { ParseBundle, ParseObject } from "../ParseTypes";
import { priceAtTier } from "../PriceTiers";
import { SimpleListItem } from "../UITypes";

export default class GPStoreContent implements Cacheable, SimpleListItem {
	_id: string;
	active?: boolean;
	additionalInformation?: string[];
	altProductImage?: string;
	appStoreId?: string;
	appStorePriceTier?: 0;
	appSection?: string;
	articleFeaturedItems?: string;
	boundsCenter?: {
		_latitude: number;
		_longitude: number;
	};

	_bundleItems?: ParseBundle[];
	composedBounds?: string[];
	country?: string;
	createdAt?: Date;
	_description?: string;
	endDate?: Date;
	envelope?: string;
	file?: string;
	fileSize?: number;
	fileVersion?: string;
	_heroImage?: string;
	iOS?: boolean;
	_ignoreImageShading?: boolean;
	image?: string;
	internalNote?: string;
	largeImage?: string;
	link?: string;
	locale?: string;
	mac?: boolean;
	macAppStoreId?: string;
	maxVersion?: string;
	messageActiveConditions?: string;
	minSubLevel?: string;
	minVersion?: string;
	minVersionMac?: string;
	_previewImages?: string[];
	previewVideos?: string;
	primaryState?: string;
	productId?: string;
	publisher?: string;
	publisherSKU?: string;
	relatedContent?: string;
	restrictions?: string[];
	startDate?: Date;
	states?: string[];
	_storyImage?: string;
	storyImageSize?: string;
	_subtitle?: string;
	testMode?: boolean;
	_title?: string;
	_type?: string;
	updatedAt?: Date;
	cacheDate: Date;
	timeToLive: number;

	constructor(parseProd?: ParseObject, ttl = MILLIS_DAY) {
		if (parseProd) {
			this._id = parseProd.id;
			this.active = parseProd.get("active");
			this.additionalInformation = parseProd.get("additionalInformation");
			this.altProductImage = parseProd.get("altProductImage");
			this.appStoreId = parseProd.get("appStoreId");
			this.appStorePriceTier = parseProd.get("appStorePriceTier");
			this.appSection = parseProd.get("app_section");
			this.articleFeaturedItems = parseProd.get("articleFeaturedItems");
			this.boundsCenter = parseProd.get("boundsCenter");
			this._bundleItems = parseProd.get("bundleItems");
			this.composedBounds = parseProd.get("composedBounds");
			this.country = parseProd.get("country");
			this.createdAt = parseProd.get("createdAt");
			this._description = parseProd.get("description");
			this.endDate = parseProd.get("endDate");
			this.envelope = parseProd.get("envelope");
			this.file = parseProd.get("file");
			this.fileSize = parseProd.get("fileSize");
			this.fileVersion = parseProd.get("fileVersion");
			this._heroImage = parseProd.get("heroImage");
			this.iOS = parseProd.get("iOS");
			this._ignoreImageShading = parseProd.get("ignoreImageShading");
			this.image = parseProd.get("image");
			this.internalNote = parseProd.get("internalNote");
			this.largeImage = parseProd.get("largeImage");
			this.link = parseProd.get("link");
			this.locale = parseProd.get("locale");
			this.mac = parseProd.get("mac");
			this.macAppStoreId = parseProd.get("macAppStoreId");
			this.maxVersion = parseProd.get("maxVersion");
			this.messageActiveConditions = parseProd.get("messageActiveConditions");
			this.minSubLevel = parseProd.get("minSubLevel");
			this.minVersion = parseProd.get("minVersion");
			this.minVersionMac = parseProd.get("minVersionMac");
			this._previewImages = parseProd.get("previewImages");
			this.previewVideos = parseProd.get("previewVideos");
			this.primaryState = parseProd.get("primaryState");
			this.productId = parseProd.get("productId");
			this.publisher = parseProd.get("publisher");
			this.publisherSKU = parseProd.get("publisherSKU");
			this.relatedContent = parseProd.get("relatedContent");
			this.restrictions = parseProd.get("restrictions");
			this.startDate = parseProd.get("startDate");
			this.states = parseProd.get("states");
			this._storyImage = parseProd.get("storyImage");
			this.storyImageSize = parseProd.get("storyImageSize");
			this._subtitle = parseProd.get("subtitle");
			this.testMode = parseProd.get("testMode");
			this._title = parseProd.get("title");
			this._type = parseProd.get("type");
			this.updatedAt = parseProd.get("updatedAt");
		} else {
			this._id = "obj" + Math.floor(Math.random() * 2000) + "rn";
		}
		this.cacheDate = new Date();
		this.timeToLive = ttl;
	}

	updateViaParseObject(parseProd?: ParseObject) {
		if (parseProd) {
			this._id = parseProd.id;
			this.active = parseProd.get("active");
			this.additionalInformation = parseProd.get("additionalInformation");
			this.altProductImage = parseProd.get("altProductImage");
			this.appStoreId = parseProd.get("appStoreId");
			this.appStorePriceTier = parseProd.get("appStorePriceTier");
			this.appSection = parseProd.get("app_section");
			this.articleFeaturedItems = parseProd.get("articleFeaturedItems");
			this.boundsCenter = parseProd.get("boundsCenter");
			this._bundleItems = parseProd.get("bundleItems");
			this.composedBounds = parseProd.get("composedBounds");
			this.country = parseProd.get("country");
			this.createdAt = parseProd.get("createdAt");
			this._description = parseProd.get("description");
			this.endDate = parseProd.get("endDate");
			this.envelope = parseProd.get("envelope");
			this.file = parseProd.get("file");
			this.fileSize = parseProd.get("fileSize");
			this.fileVersion = parseProd.get("fileVersion");
			this._heroImage = parseProd.get("heroImage");
			this.iOS = parseProd.get("iOS");
			this._ignoreImageShading = parseProd.get("ignoreImageShading");
			this.image = parseProd.get("image");
			this.internalNote = parseProd.get("internalNote");
			this.largeImage = parseProd.get("largeImage");
			this.link = parseProd.get("link");
			this.locale = parseProd.get("locale");
			this.mac = parseProd.get("mac");
			this.macAppStoreId = parseProd.get("macAppStoreId");
			this.maxVersion = parseProd.get("maxVersion");
			this.messageActiveConditions = parseProd.get("messageActiveConditions");
			this.minSubLevel = parseProd.get("minSubLevel");
			this.minVersion = parseProd.get("minVersion");
			this.minVersionMac = parseProd.get("minVersionMac");
			this._previewImages = parseProd.get("previewImages");
			this.previewVideos = parseProd.get("previewVideos");
			this.primaryState = parseProd.get("primaryState");
			this.productId = parseProd.get("productId");
			this.publisher = parseProd.get("publisher");
			this.publisherSKU = parseProd.get("publisherSKU");
			this.relatedContent = parseProd.get("relatedContent");
			this.restrictions = parseProd.get("restrictions");
			this.startDate = parseProd.get("startDate");
			this.states = parseProd.get("states");
			this._storyImage = parseProd.get("storyImage");
			this.storyImageSize = parseProd.get("storyImageSize");
			this._subtitle = parseProd.get("subtitle");
			this.testMode = parseProd.get("testMode");
			this._title = parseProd.get("title");
			this._type = parseProd.get("type");
			this.updatedAt = parseProd.get("updatedAt");
		} else {
			this._id = "obj" + Math.floor(Math.random() * 2000) + "rn";
		}
	}

	public static clone(gpc: GPStoreContent): GPStoreContent {
		const copy = new GPStoreContent();
		copy.active = gpc.active;
		copy.additionalInformation = gpc.additionalInformation;
		copy.altProductImage = gpc.altProductImage;
		copy.appStoreId = gpc.appStoreId;
		copy.appStorePriceTier = gpc.appStorePriceTier;
		copy.appSection = gpc.appSection;
		copy.articleFeaturedItems = gpc.articleFeaturedItems;
		copy.boundsCenter = gpc.boundsCenter;
		copy._bundleItems = gpc._bundleItems;
		copy.composedBounds = gpc.composedBounds;
		copy.country = gpc.country;
		copy.createdAt = gpc.createdAt;
		copy._description = gpc._description;
		copy.endDate = gpc.endDate;
		copy.envelope = gpc.envelope;
		copy.file = gpc.file;
		copy.fileSize = gpc.fileSize;
		copy.fileVersion = gpc.fileVersion;
		copy._heroImage = gpc._heroImage;
		copy._id = gpc._id;
		copy.iOS = gpc.iOS;
		copy._ignoreImageShading = gpc._ignoreImageShading;
		copy.image = gpc.image;
		copy.internalNote = gpc.internalNote;
		copy.largeImage = gpc.largeImage;
		copy.link = gpc.link;
		copy.locale = gpc.locale;
		copy.mac = gpc.mac;
		copy.macAppStoreId = gpc.macAppStoreId;
		copy.maxVersion = gpc.maxVersion;
		copy.messageActiveConditions = gpc.messageActiveConditions;
		copy.minSubLevel = gpc.minSubLevel;
		copy.minVersion = gpc.minVersion;
		copy.minVersionMac = gpc.minVersionMac;
		copy._previewImages = gpc._previewImages;
		copy.previewVideos = gpc.previewVideos;
		copy.primaryState = gpc.primaryState;
		copy.productId = gpc.productId;
		copy.publisher = gpc.publisher;
		copy.publisherSKU = gpc.publisherSKU;
		copy.relatedContent = gpc.relatedContent;
		copy.restrictions = gpc.restrictions;
		copy.startDate = gpc.startDate;
		copy.states = gpc.states;
		copy._storyImage = gpc._storyImage;
		copy.storyImageSize = gpc.storyImageSize;
		copy._subtitle = gpc._subtitle;
		copy.testMode = gpc.testMode;
		copy._title = gpc._title;
		copy._type = gpc._type;
		copy.updatedAt = gpc.updatedAt;
		copy.cacheDate = gpc.cacheDate;
		copy.timeToLive = gpc.timeToLive;
		return copy;
	}

	get id(): string {
		return this._id;
	}

	get title(): string {
		return this._title || "";
	}

	get subtitle(): string {
		return this._subtitle || "";
	}

	get description(): string {
		return this._description || "";
	}

	get defaultImage(): string {
		return this.image || "";
	}

	get icon(): string {
		return this.image || "";
	}

	get heroImage(): string {
		return this._heroImage || "";
	}

	get ignoreImageShading(): boolean {
		//I am turning off image shadows on the website
		return true;
		// return this._ignoreImageShading || false;
	}

	get storyImage(): string {
		return this._storyImage || "";
	}

	get type(): string {
		return this._type || "";
	}

	get bundleItems(): ParseBundle[] {
		if (this._bundleItems !== undefined) {
			return this._bundleItems;
		}
		return [];
	}

	get previewImages(): string[] {
		if (this._previewImages !== undefined) {
			return this._previewImages;
		}
		return [];
	}

	get routeLink(): string {
		const type = this._type;
		let route = "";
		if (
			type === "bundle" ||
			type === "map" ||
			type === "huntingUnit" ||
			type === "inlcudedMap"
		) {
			route = "store/product/" + this.productId;
		} else if (type === "review") {
			route = "store/content?review=" + this.internalNote;
			/* } else if () { */
		} else {
			const link = this.link;
			if (link) {
				route = link.split("//")[1];
				if (route.startsWith("store?pageid=")) {
					const pageID = route.split("=")[1];
					route = `store/page/${pageID}`;
				}
			}
		}

		return route;
	}

	get clickLink(): string {
		return this.routeLink;
	}

	/**
	 * Creates a GPStoreContent object that only contains the information
	 * necessary to calculate shouldShow(), isActive(), and isDateValid();
	 * @param content to create a filter faccade from
	 */
	public static filterFaccade(content: ParseObject): GPStoreContent {
		const facade = new GPStoreContent();
		if (content) {
			facade.active = content.get("active");
			facade.startDate = content.get("startDate");
			facade.endDate = content.get("endDate");
		}
		return facade;
	}

	shouldShow(): boolean {
		return this.isActive() && this.isDateValid();
	}

	isActive(): boolean {
		return this.active || false;
	}

	isDateValid(): boolean {
		const startDate = this.startDate || null;
		const endDate = this.endDate || null;
		const today = new Date();
		return (
			(startDate !== null && today >= startDate) ||
			(endDate !== null && today < endDate) ||
			(startDate === null && endDate === null)
		);
	}

	readableFileSize(): string {
		if (this.fileSize) {
			let curSize = this.fileSize / 1000000;
			if (curSize > 1000) {
				curSize = curSize / 1000;
				return curSize.toFixed(1) + " GB";
			} else {
				return curSize.toFixed(2) + " MB";
			}
		}
		return "";
	}

	platforms(): string {
		const list = [];
		if (this.iOS) {
			list.push("iPhone");
			list.push("iPad");
			list.push("Apple Watch");
		}
		if (this.mac) {
			list.push("Mac");
		}
		list[list.length - 1] = "& " + list[list.length - 1];
		return list.join(", ");
	}

	price(currency = "USD"): string {
		const priceTier = this.appStorePriceTier;
		if (priceTier) {
			return String(priceAtTier(priceTier, currency));
		}
		return "";
	}
}
