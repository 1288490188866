/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	aws_project_region: "us-east-2",
	aws_appsync_graphqlEndpoint:
		"https://6pvyjhct65c4fkibfiy3jyhefi.appsync-api.us-east-2.amazonaws.com/graphql",
	aws_appsync_region: "us-east-2",
	aws_appsync_authenticationType: "API_KEY",
	aws_appsync_apiKey: "da2-vmjttzbywnfjhdkvafochhg5ye",
};

export default awsmobile;
