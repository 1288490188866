import { coreData } from "@/services/CoreData";
import { Cacheable, MILLIS_DAY } from "@/vault/Cacheable";
import { ParseObject } from "../ParseTypes";
import GPStoreSection from "./GPStoreSection";

export default class GPStorePage implements Cacheable {
	static getPage(arg0: string): any {
		throw new Error("Method not implemented.");
	}
	active?: boolean;
	appSection?: string;
	identifier?: string;
	iOS?: boolean;
	sections?: string[];
	updatedAt?: Date;
	title?: string;
	createdAt?: Date;
	cacheDate: Date;
	timeToLive: number;

	constructor(gpsp?: GPStorePage) {
		if (gpsp) {
			this.title = gpsp.title;
			this.sections = gpsp.sections;
		}
		this.cacheDate = new Date();
		this.timeToLive = MILLIS_DAY;
	}

	public static clone(gpsp: GPStorePage): GPStorePage {
		const copy = new GPStorePage();
		copy.title = gpsp.title;
		copy.sections = gpsp.sections;
		copy.active = gpsp.active;
		copy.identifier = gpsp.identifier;
		copy.iOS = gpsp.iOS;
		copy.createdAt = gpsp.createdAt;
		copy.updatedAt = gpsp.updatedAt;
		copy.appSection = gpsp.appSection;
		copy.cacheDate = gpsp.cacheDate;
		copy.timeToLive = gpsp.timeToLive;
		return copy;
	}

	/**
	 * Creates a new GPStorePage from a Parse GPStorePage object
	 * @param parseProd A Parse GPStorePage object
	 */
	public static newFromPFO(parseProd?: ParseObject): GPStorePage {
		const gpo = new GPStorePage();
		if (parseProd) {
			gpo.identifier = parseProd.get("identifier");
			gpo.title = parseProd.get("title");
			gpo.sections = parseProd.get("sections");

			gpo.active = parseProd.get("active");
			gpo.appSection = parseProd.get("app_section");
			gpo.iOS = parseProd.get("iOS");
			gpo.updatedAt = parseProd.get("updatedAt");
			gpo.createdAt = parseProd.get("createdAt");
		}
		return gpo;
	}

	public async getLoadedSections(): Promise<GPStoreSection[]> {
		console.log(`getting sections for identifier: ${this.identifier}`);
		if (this.identifier) {
			return await coreData.getStoreSectionsForPage(this.identifier);
		} else {
			return [];
		}

		// return store.getters["gpstore/getSectionsForPage"](this.identifier);
	}

	public static newFromSects(
		title: string,
		sections: ParseObject[]
	): GPStorePage {
		const gpo = new GPStorePage();
		gpo.title = title;
		gpo.sections = sections.map((section) => {
			return section.id;
		});
		return gpo;
	}

	public static newFromGPSects(title: string, sections: string[]): GPStorePage {
		const gpo = new GPStorePage();
		gpo.title = title;
		gpo.sections = sections;
		return gpo;
	}

	public addTitle(title: string): GPStorePage {
		this.title = title;
		return this;
	}

	public addSections(sections: ParseObject[]): GPStorePage {
		this.sections = sections.map((section) => {
			return section.id;
		});
		return this;
	}

	public addSection(section: ParseObject): GPStorePage {
		if (this.sections) {
			this.sections.push(section.id);
		} else {
			this.sections = [section.id];
		}
		return this;
	}
}
