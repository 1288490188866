
import { defineComponent } from "vue";
export default defineComponent({
	props: {
		imgurl: {
			type: String,
			required: true,
		},
		size: {
			type: Number,
			default: 256,
		},
	},
	computed: {
		borderSize(): number {
			return Math.floor(Math.sqrt(this.size) / 2);
		},
		sizeStyle(): string {
			return `size: ${this.size}px; border: ${this.borderSize}px solid #1b6116;`;
		},
		imgStyleObj(): any {
			return {
				height: `${this.size}px`,
				width: `${this.size}px`,
				border: `${this.borderSize}px solid #1b6116`,
				"border-radius": "100%",
				"background-color": "white",
			};
		},
	},
});
